import { useRef, useEffect } from "react";

import Header from "../../components/Header.js";
import Footer from "../../components/Footer.js";

import { InlineShareButtons } from "sharethis-reactjs";

const CnameDnsBlogPage = () => {
  useEffect(() => {
    document.getElementById("canonical").href =
      "https://solutionops.cl/categorias/desarrollo-de-software/cname-dns";
    document.getElementById("og_site_title").content =
      "CNAME y DNS";
    document.getElementById("site_keywords").content =
      "Entendiendo las diferencias clave en la configuración de tu infraestructura web";
    document.getElementById("og_site_description").content =
      "Entendiendo las diferencias clave en la configuración de tu infraestructura web";
    document.getElementById("site_description").content =
      "Entendiendo las diferencias clave en la configuración de tu infraestructura web";
  }, []);

  const scrollToRef = (ref) =>
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: "smooth",
    });
  const contactRef = useRef(null);

  const openWhatsapp = () => {
    let url =
      "https://api.whatsapp.com/send/?phone=56991981647&text&app_absent=0";
    window.open(url);
  };

  const goTo = (page) => {
    window.location.assign(page);
    // let url = page;
    // window.href(url);
  };

  const openCall = () => {
    let url = "tel:0056991981647";
    window.open(url);
  };

  return (
    <div className="App">
      <Header
        goTo={goTo}
        scrollToRef={scrollToRef}
        openWhatsapp={openWhatsapp}
        openCall={openCall}
      />

      <div className="container-fluid bg-blog  bg-grey">
        <div className="container main-div-title bg-white">
          <div className="row">
            <div className="col-md-12">
              <h1 className="blog-title">
                Diferencias entre CNAME y registro DNS tipo A
              </h1>
              <h2 className="blog-subtitle">
                Entendiendo las diferencias clave en la configuración de tu
                infraestructura web
              </h2>
              <br />
              <br />
            </div>
            <div className="col-lg-8">
              <p className="blog-text">
                Al trabajar con la gestión DNS de un dominio podemos sin duda
                encontrarnos con los términos CNAME y registro de DNS tipo A.
                Ambos son registros utilizados para asociar un nombre de dominio
                con una dirección IP, pero tienen diferencias importantes. En
                este artículo, exploraremos de manera resumida las diferencias
                clave entre un CNAME y un registro DNS tipo A.
              </p>
              <h3 className="h3-title">¿Qué es un registro DNS tipo A?</h3>
              <p className="blog-text">
                Es el registro básico y común en DNS. Vincula un nombre de
                dominio con una dirección IP específica. Cuando se ingresa el
                nombre de dominio en el navegador, este tipo de registro
                resuelve la solicitud y la envía al servidor correspondiente
                utilizando la dirección IP asociada.
              </p>
              <h3 className="h3-title">¿Qué es un CNAME?</h3>
              <p className="blog-text">
                Es un registro que apunta un nombre de dominio a otro nombre de
                dominio en lugar de una dirección IP. Se utiliza para crear
                alias o redirigir un nombre de dominio a otro servidor sin
                cambiar la dirección IP en múltiples registros DNS tipo A.
              </p>
              <h3 className="h3-title">
                Diferencias clave entre CNAME y registro DNS tipo A:
              </h3>
              <p className="blog-text">
                Destino de la resolución: El registro DNS tipo A apunta
                directamente a una dirección IP, mientras que un CNAME apunta a
                otro nombre de dominio.
              </p>
              <p className="blog-text">
                Número de resoluciones: Un registro DNS tipo A requiere una
                única resolución para llegar a la dirección IP deseada, mientras
                que un CNAME puede requerir múltiples resoluciones si el nombre
                de dominio de destino tiene otros CNAME o registros DNS tipo A
                asociados.
              </p>
              <p className="blog-text">
                Uso y flexibilidad: Los registros DNS tipo A son ideales para
                asignar un nombre de dominio a una dirección IP específica,
                mientras que los CNAME son útiles cuando se desea redirigir un
                nombre de dominio a otro nombre de dominio sin cambiar la
                dirección IP asociada.
              </p>

              <h3 className="h3-title">Conclusión:</h3>
              <p className="blog-text">
                Tanto los registros DNS tipo A como los CNAME son importantes en
                la configuración del DNS. El registro DNS tipo A apunta
                directamente a una dirección IP, mientras que el CNAME apunta a
                otro nombre de dominio. La elección entre ellos depende de si se
                necesita asignar una dirección IP específica o redirigir a otro
                nombre de dominio.
              </p>

              <p className="blog-text">
                {
                  "<solutionops-team> Autor: Elias Schotborgh </solutionops-team>"
                }
              </p>
            </div>
            <div className="col-lg-4">
              <InlineShareButtons
                config={{
                  alignment: "center",
                  color: "social",
                  enabled: true,
                  font_size: 16,
                  networks: [
                    "whatsapp",
                    "linkedin",
                    "messenger",
                    "facebook",
                    "twitter",
                    "email",
                  ],
                  padding: 12,
                  radius: 0,
                  show_total: false,
                  size: 40,
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <Footer openWhatsapp={openWhatsapp} />
    </div>
  );
};

export default CnameDnsBlogPage;
