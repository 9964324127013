import { useRef, useEffect } from "react";

import Header from "../../components/Header.js";
import Footer from "../../components/Footer.js";

import { InlineShareButtons } from "sharethis-reactjs";

const CeremoniasScrumBlogPage = () => {
  useEffect(() => {
    document.getElementById("canonical").href =
      "https://solutionops.cl/categorias/desarrollo-de-software/ceremonias-scrum";
    document.getElementById("og_site_title").content = "Ceremonias de Scrum";
    document.getElementById("site_keywords").content =
      "Gestión ágil y efectiva del desarrollo de proyectos";
    document.getElementById("og_site_description").content =
      "Gestión ágil y efectiva del desarrollo de proyectos";
    document.getElementById("site_description").content =
      "Gestión ágil y efectiva del desarrollo de proyectos";
  }, []);

  const scrollToRef = (ref) =>
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: "smooth",
    });
  const contactRef = useRef(null);

  const openWhatsapp = () => {
    let url =
      "https://api.whatsapp.com/send/?phone=56991981647&text&app_absent=0";
    window.open(url);
  };

  const goTo = (page) => {
    window.location.assign(page);
    // let url = page;
    // window.href(url);
  };

  const openCall = () => {
    let url = "tel:0056991981647";
    window.open(url);
  };

  return (
    <div className="App">
      <Header
        goTo={goTo}
        scrollToRef={scrollToRef}
        openWhatsapp={openWhatsapp}
        openCall={openCall}
      />

      <div className="container-fluid bg-blog  bg-grey">
        <div className="container main-div-title bg-white">
          <div className="row">
            <div className="col-md-12">
              <h1 className="blog-title">Principales Ceremonias en Scrum</h1>
              <h2 className="blog-subtitle">
                Los rituales clave para una gestión ágil y efectiva del
                desarrollo de proyectos
              </h2>
              <br />
              <br />
            </div>
            <div className="col-lg-8">
              <p className="blog-text">
                Scrum, un marco ágil utilizado en proyectos colaborativos,
                destaca por sus ceremonias, reuniones periódicas que promueven
                la comunicación, la colaboración y la mejora continua. En este
                artículo, destacaremos las principales ceremonias de Scrum:
                Daily Scrum, Sprint Review, Sprint Planning y Sprint
                Retrospective. Estas ceremonias son fundamentales para el éxito
                de un proyecto Scrum.
              </p>
              <h3 className="h3-title">Daily Scrum:</h3>
              <p className="blog-text">
                Reunión diaria para sincronizar al equipo y planificar el
                trabajo del día. Cada miembro comparte progresos, planes y
                obstáculos. Objetivo: transparencia, colaboración y resolución
                temprana de problemas.
              </p>
              <h3 className="h3-title">Sprint Review:</h3>
              <p className="blog-text">
                Revisión al final del sprint para mostrar los resultados al
                cliente y stakeholders. Se presentan las funcionalidades
                completadas y se recopila el feedback. Oportunidad de evaluar y
                ajustar el producto según las necesidades del cliente.
              </p>
              <h3 className="h3-title">Sprint Planning:</h3>
              <p className="blog-text">
                Planificación al inicio del sprint. El equipo y el Product Owner
                seleccionan historias de usuario y estiman el esfuerzo.
                Objetivo: definir el objetivo del sprint y las tareas necesarias
                para alcanzarlo.
              </p>
              <h3 className="h3-title">Sprint Retrospective:</h3>
              <p className="blog-text">
                Reflexión al final del sprint para mejorar continuamente. Se
                analizan éxitos, desafíos y se identifican acciones de mejora.
                Fomenta el aprendizaje y la adaptación para sprints futuros.
              </p>
              <p className="blog-text">
                Las ceremonias en Scrum, como el Daily Scrum, la Sprint Review,
                la Sprint Planning y la Sprint Retrospective, son fundamentales
                para el éxito de los proyectos ágiles. Estas reuniones promueven
                la colaboración, la transparencia y la mejora continua del
                equipo. Utilizadas de manera efectiva, los equipos Scrum
                optimizan su productividad, entregan valor y se adaptan
                rápidamente a los cambios en el proyecto.
              </p>

              <p className="blog-text">
                {
                  "<solutionops-team> Autor: Elias Schotborgh </solutionops-team>"
                }
              </p>
            </div>
            <div className="col-lg-4">
              <InlineShareButtons
                config={{
                  alignment: "center",
                  color: "social",
                  enabled: true,
                  font_size: 16,
                  networks: [
                    "whatsapp",
                    "linkedin",
                    "messenger",
                    "facebook",
                    "twitter",
                    "email",
                  ],
                  padding: 12,
                  radius: 0,
                  show_total: false,
                  size: 40,
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <Footer openWhatsapp={openWhatsapp} />
    </div>
  );
};

export default CeremoniasScrumBlogPage;
