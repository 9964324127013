import { useRef, useEffect } from 'react';

import Header from '../../components/Header.js';
import Footer from '../../components/Footer.js';

import {InlineShareButtons} from 'sharethis-reactjs';

const AsyncAwaitBlogPage = () => {

  useEffect(() => {
    document.getElementById("canonical").href = "https://solutionops.cl/categorias/desarrollo-de-software/async-await-en-node-js";
    document.getElementById("og_site_title").content = "Async/Await para código secuencial - NodeJs";
    document.getElementById("site_keywords").content = "Async, Await, NodeJS, Javascript, Asincrono, Sincrono";
    document.getElementById("og_site_description").content = "Async Await en javascript NodeJs, código asincrono y sincrono javascript Node JS";
    document.getElementById("site_description").content = "Async Await en javascript NodeJs, código asincrono y sincrono javascript Node JS";
  }, []);

  const scrollToRef = (ref) =>  window.scrollTo({
    top: ref.current.offsetTop,
    behavior: 'smooth',
  });
  const contactRef = useRef(null);
    
  const openWhatsapp = () => {
    let url = "https://api.whatsapp.com/send/?phone=56991981647&text&app_absent=0";
    window.open(url);
  }

  const goTo = (page) => {
    window.location.assign(page)
    // let url = page;
    // window.href(url);
  }

  const openCall = () => {
    let url = "tel:0056991981647";
    window.open(url);
  }

  return (
    <div className="App">
        <Header
            goTo={goTo}
            scrollToRef={scrollToRef}
            openWhatsapp={openWhatsapp}
            openCall={openCall}
        />

        <div className="container-fluid bg-blog  bg-grey">
            <div className="container main-div-title bg-white">
                <div className="row">
                    <div className='col-md-12'>
                        <h1 className="blog-title">Async/Await para código secuencial -  NodeJs JavaScript</h1>
                        <h2 className="blog-subtitle">¿Cómo utilizar de forma correcta el Async/Await para códigos de respuesta secuencial?</h2>
                        <br/>
                    </div>
                    <div className='col-lg-8'>
                        <img
                            loading="lazy"
                            width="auto"
                            height="auto"
                            className="img-blog"
                            alt="async-await-solutionops-desarrollo-de-software-javascript"
                            src={"/static/img/blog/async-await-solutionops-desarrollo-de-software-javascript.png"}/>

                            <p className='blog-text'>Async y await son características que nos permiten escribir código asíncrono de forma más sencilla y fácil de leer.</p>
                            <p className='blog-text'>En esencia async/await es una forma de trabajar con funciones asíncronas de JavaScript que se introdujo en ECMAScript 2017. La principal ventaja de async/await es que nos permite escribir código asíncrono de una manera que se asemeja más a la forma en que escribimos código síncrono.</p>
                            <p className='blog-text'>En lugar de anidar callbacks o utilizar Promises encadenadas, podemos simplemente marcar una función como asíncrona con la palabra clave "async" y luego utilizar la palabra clave "await" dentro de la función para esperar a que se resuelva una Promise antes de continuar.</p>
                            <pre>
                                <code>
                                    {`
const countUntil = (timeInMiliseconds) => {
    return new Promise((resolve, reject)=>{
        setTimeout(() => {
            resolve('Respuesta de consulta con valor en milisegundos: '+timeInMiliseconds);
        }, timeInMiliseconds);
    });
}

const init = async () => {
    console.log("Log 1")
    const response = await countUntil(1000);
    console.log(response);
    console.log("Log 2")
}

init();
                                    `}
                                </code>
                            </pre>
                            <p className='blog-text'>Por ejemplo, en el código anterior se realiza la ejecución de una función donde se utiliza await para invocar una promesa, esto ocasiona que la respuesta se muestre de forma secuencial, es decir que en la consola se mostrará lo siguiente: </p>

                            <pre>
                                <code>
                                    {`
Log 1
Respuesta de consulta con valor en milisegundos: 1000
Log 2
                                    `}
                                </code>
                            </pre>

                            <p className='blog-text'>En conclusión, await se puede utilizar para detener el procesamiento secuencial del código de una función hasta que se obtenga la resolución de otra y así tener una respuesta ordenada y sin necesidad de anidar funciones o utilizar callbacks para obtener la respuesta de un método asíncrono.</p>
                            <p className='blog-text'>{'<solutionops-team> Happy Coding! </solutionops-team>'}</p>

                    </div>
                    <div className='col-lg-4'>
                        <InlineShareButtons
                            config={{
                                alignment: 'center',
                                color: 'social',
                                enabled: true,
                                font_size: 16,
                                networks: [
                                'whatsapp',
                                'linkedin',
                                'messenger',
                                'facebook',
                                'twitter',
                                'email'
                                ],
                                padding: 12,
                                radius: 0,
                                show_total: false,
                                size: 40
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>

        <Footer
            openWhatsapp={openWhatsapp}
        />
    </div>
  );
}

export default AsyncAwaitBlogPage;
