import { useRef, useEffect } from 'react';

import Header from '../components/Header.js';
import Footer from '../components/Footer.js';

import Blog from '../segments/Blog.js';

// import Loader from 'react-loader-spinner';

import TestingService from '../services/TestingService.js';


const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const DevelopmentPage = () => {

  useEffect(() => {
    document.getElementById("canonical").href = "https://solutionops.cl/servicios/desarrollo-de-software";
    document.getElementById("og_site_title").content = "Desarrollo de Software - SolutionOps";
    document.getElementById("site_keywords").content = "Desarrollo de software, Desarrollo de software a medida, Desarrollo de Aplicaciones móviles, Android, IOS";
    document.getElementById("og_site_description").content = "Desarrollo de Software para aplicaciones web y móviles";
    document.getElementById("site_description").content = "Desarrollo de Software para aplicaciones web y móviles";
  }, []);

  const serviceRef = useRef(null);
  const plansRef = useRef(null);
  const contactRef = useRef(null);
  // const [loading, setLoading] = useState(true);

  const openWhatsapp = () => {
    let url = "https://api.whatsapp.com/send/?phone=56991981647&text&app_absent=0";
    window.open(url);
  }

  const goTo = (page) => {
    window.location.assign(page)
    // let url = page;
    // window.href(url);
  }

  const openCall = () => {
    let url = "tel:0056991981647";
    window.open(url);
  }

  return (
    <div className="App">
      <Header
        goTo={goTo}
        scrollToRef={scrollToRef}
        openWhatsapp={openWhatsapp}
        openCall={openCall}
      />
      <Blog/>
      <Footer
        openWhatsapp={openWhatsapp}
      />
    </div>
  );
}

export default DevelopmentPage;
