import { useRef, useEffect } from 'react';

import Header from '../../components/Header.js';
import Footer from '../../components/Footer.js';

import {InlineShareButtons} from 'sharethis-reactjs';

const CardinalidadesBlogPage = () => {

  useEffect(() => {
    document.getElementById("canonical").href = "https://solutionops.cl/categorias/desarrollo-de-software/cardinalidades-bases-de-datos";
    document.getElementById("og_site_title").content = "Cardinalidades en bases de datos";
    document.getElementById("site_keywords").content = "Cardinalidades en bases de datos";
    document.getElementById("og_site_description").content = "Cardinalidades en bases de datos";
    document.getElementById("site_description").content = "Cardinalidades en bases de datos";
  }, []);

  const scrollToRef = (ref) =>  window.scrollTo({
    top: ref.current.offsetTop,
    behavior: 'smooth',
  });
  const contactRef = useRef(null);
    
  const openWhatsapp = () => {
    let url = "https://api.whatsapp.com/send/?phone=56991981647&text&app_absent=0";
    window.open(url);
  }

  const goTo = (page) => {
    window.location.assign(page)
    // let url = page;
    // window.href(url);
  }

  const openCall = () => {
    let url = "tel:0056991981647";
    window.open(url);
  }

  return (
    <div className="App">
        <Header
            goTo={goTo}
            scrollToRef={scrollToRef}
            openWhatsapp={openWhatsapp}
            openCall={openCall}
        />

        <div className="container-fluid bg-blog  bg-grey">
            <div className="container main-div-title bg-white">
                <div className="row">
                    <div className='col-md-12'>
                        <h1 className="blog-title">Cardinalidades en bases de datos</h1>
                        <br/>
                    </div>
                    <div className='col-lg-8'>
         

                            <p className='blog-text'>La cardinalidad es uno de los conceptos fundamentales al momento de explorar las relaciones almacenadas en una base de datos. Define la cantidad de filas relacionadas de una entidad A con una entidad B. Normalmente, estas relaciones se realizan utilizando las claves primarias y claves foráneas de cada tabla. Dentro de los tipos de cardinalidades, podemos identificar principalmente cuatro.</p>
                            <h3 className='h3-title'>Uno a Uno (1:1)</h3>
                            <p className='blog-text'>Un registro de una entidad A, se relaciona de forma directa y exclusiva con un registro de una entidad B.</p>
                            <p className='blog-text'>Por ejemplo, si nos situamos en un escenario hipotético de una empresa de transporte, diremos que a un chofer solo se le puede asignar un vehículo, y que el vehículo no podrá ser asignado a otro chofer de forma simultánea. En este caso estamos ante una relación de tipo 1:1, y la llave primaria de una de las entidades deberá pasar como llave foránea a la otra entidad. Se diagramaría de la siguiente forma</p>

                            <img
                                loading="lazy"
                                width="auto"
                                height="auto"
                                className="img-blog"
                                alt="async-await-solutionops-desarrollo-de-software-javascript"
                                src={"/static/img/blog/cardinalidades-1-solutionops.png"}/>


                            <h3 className='h3-title'>Uno a Muchos (1:N) </h3>
                            <p className='blog-text'>Un registro de una entidad B, se puede relacionar de forma simultánea con muchos registros de una entidad A. </p>
                            <p className='blog-text'>Siguiendo con el ejemplo anterior, diremos que la empresa de transporte ha decidido implementar turnos fijos, por lo que los vehículos se asignarán a uno o más choferes para que puedan ser utilizados en diferentes turnos, sin embargo, los choferes solo podrán tener un vehículo asignado y será el que utilizarán en su turno. En este caso hablamos de una relación uno a muchos, es decir, un vehículo puede ser asignado a muchos choferes. A la entidad que representa el “muchos” de la relación se le deberá asignar la llave foránea de la entidad que representa el “uno”. Se diagramaría de la siguiente forma</p>

                            <img
                                loading="lazy"
                                width="auto"
                                height="auto"
                                className="img-blog"
                                alt="async-await-solutionops-desarrollo-de-software-javascript"
                                src={"/static/img/blog/cardinalidades-2-solutionops.png"}/>


                            <h3 className='h3-title'>Muchos a uno (N:1)</h3>
                            <p className='blog-text'>En un sentido práctico, la relación de muchos a uno difiere solo en la dirección desde donde se aprecia la relación de uno a muchos. Es decir, muchos registros de una entidad A se puede relacionar con un solo registro de una entidad B.</p>
                            <p className='blog-text'>Utilizando el mismo ejemplo anterior, podemos decir que muchos choferes pueden ser asignados a un vehículo. Se diagramaría de la misma forma que el ejemplo graficado en la relación 1:N. </p>

                            <h3 className='h3-title'>Muchos a muchos (N:M)</h3>
                            <p className='blog-text'>Muchos registros de una entidad A pueden relacionarse con muchos registros de una entidad B. Esta es, quizás, la relación que más confusión causa al momento de abordar las cardinalidades.</p>
                            <p className='blog-text'>En este caso, y siguiendo con el contexto de una empresa de transporte, imaginemos que se ha decido que un vehículo no estará asignado a un solo chofer, sino que los vehículos se irán rotando dependiendo de la disponibilidad de estos, y los trayectos que se deban realizar. Dichos en otras palabras, un vehículo puede ser conducido por uno o más choferes, y un chofer conducirá uno o más vehículos dentro de su turno</p>

                            <img
                                loading="lazy"
                                width="auto"
                                height="auto"
                                className="img-blog"
                                alt="async-await-solutionops-desarrollo-de-software-javascript"
                                src={"/static/img/blog/cardinalidades-3-solutionops.png"}/>


                            <p className='blog-text'>En este caso tenemos las mismas dos entidades utilizadas hasta ahora, pero con un nuevo problema: </p>
                            <p className='blog-text' style={{ fontStyle:"italic", fontWeight: 300 }}>¿Cómo podemos relacionar ambas tablas, permitiendo que muchos registros de una se relacionen con muchos registros de la otra?</p>
                            <p className='blog-text'>Si utilizamos la solución empleada hasta ahora con los otros casos (convertir la llave primaria de una entidad en llave foránea de la otra), veremos que no es factible, dado que cada uno de los registros en donde se incluya la llave foránea solo podrá estar relacionado al registro que hace referencia dicha llave. </p>
                            <p className='blog-text'>Para solucionar lo anterior, es necesario hacer uso de una tercera tabla que nos ayude a relacionar ambas entidades, la que se suele denominar como “tabla asociativa”, y que mantendrá una relación de uno a muchos con cada una de las entidades involucradas en la relación. Esta tabla asociativa incluirá como llave foránea tanto la llave primaria de la entidad chofer, como la llave primaria de la entidad vehículo. Esta relación se diagramaría de la siguiente forma.</p>

                            <img
                                loading="lazy"
                                width="auto"
                                height="auto"
                                className="img-blog"
                                alt="async-await-solutionops-desarrollo-de-software-javascript"
                                src={"/static/img/blog/cardinalidades-4-solutionops.png"}/>


                            <p className='blog-text'>{'<solutionops-team> Autor: Jonathan Urrutia </solutionops-team>'}</p>

                    </div>
                    <div className='col-lg-4'>
                        <InlineShareButtons
                            config={{
                                alignment: 'center',
                                color: 'social',
                                enabled: true,
                                font_size: 16,
                                networks: [
                                'whatsapp',
                                'linkedin',
                                'messenger',
                                'facebook',
                                'twitter',
                                'email'
                                ],
                                padding: 12,
                                radius: 0,
                                show_total: false,
                                size: 40
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>

        <Footer
            openWhatsapp={openWhatsapp}
        />
    </div>
  );
}

export default CardinalidadesBlogPage;
