import {useState} from 'react';
import Reaptcha from 'reaptcha';

import TelegramService from '../services/TelegramService';

const Development = ({
  goTo
}) => {

  return (
    <div className="container-fluid px-0 dark-background hght250 pdeasg100">
      <div className="row no-gutters pdng5perct">
        <div className="col-md-6" style={{paddingTop:"80px", paddingBottom:"80px"}}>
          <h2 className="white-text">Digitalizamos Su Negocio <img
              loading="lazy"
              width="32"
              height="32"
              className="img-owl-check"
              alt={"check"}
              src={"/static/img/check-mark.png"}/></h2>
          <p className="p-text-white">
            <ol>
              <li className="li_itm_1">Levantamos sus requerimientos</li>
              <li className="li_itm_1">Analizamos sus procesos</li>
              <li className="li_itm_1">Preparamos una propuesta</li>
              <li className="li_itm_1">Desarrollamos e Implementamos</li>
            </ol>
          </p>
          <button
                    type="submit"
                    className="btn btn-custom-submit-contact"
                    onClick={()=>goTo('/contacto-web')}>{'Más Información'}</button>
        </div>
        <div className="col-md-6 ta-768min">
          <img
              loading="lazy"
              className="img-owl-1"
              width="300"
              height="450"
              alt={"ideas-tecnologia-solutionops"}
              src={"/static/img/ideas-solutionops-bg.webp"}/>
        </div>
      </div>
    </div>
  )
}

export default Development;
