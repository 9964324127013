import { useRef, useEffect } from "react";

import Header from "../../components/Header.js";
import Footer from "../../components/Footer.js";

import { InlineShareButtons } from "sharethis-reactjs";

const RedesNeuronalesBlogPage = () => {
  useEffect(() => {
    document.getElementById("canonical").href =
      "https://solutionops.cl/categorias/desarrollo-de-software/redes-neuronales";
    document.getElementById("og_site_title").content =
      "Funcionamiento Básico de Redes Neuronales";
    document.getElementById("site_keywords").content =
      "Un viaje al interior de las Redes Neuronales: Entendiendo su funcionamiento básico";
    document.getElementById("og_site_description").content =
      "Un viaje al interior de las Redes Neuronales: Entendiendo su funcionamiento básico";
    document.getElementById("site_description").content =
      "Un viaje al interior de las Redes Neuronales: Entendiendo su funcionamiento básico";
  }, []);

  const scrollToRef = (ref) =>
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: "smooth",
    });
  const contactRef = useRef(null);

  const openWhatsapp = () => {
    let url =
      "https://api.whatsapp.com/send/?phone=56991981647&text&app_absent=0";
    window.open(url);
  };

  const goTo = (page) => {
    window.location.assign(page);
    // let url = page;
    // window.href(url);
  };

  const openCall = () => {
    let url = "tel:0056991981647";
    window.open(url);
  };

  return (
    <div className="App">
      <Header
        goTo={goTo}
        scrollToRef={scrollToRef}
        openWhatsapp={openWhatsapp}
        openCall={openCall}
      />

      <div className="container-fluid bg-blog  bg-grey">
        <div className="container main-div-title bg-white">
          <div className="row">
            <div className="col-md-12">
              <h1 className="blog-title">
                Funcionamiento Básico de Redes Neuronales
              </h1>
              <h2 className="blog-subtitle">
                Un viaje al interior de las Redes Neuronales: Entendiendo su
                funcionamiento básico
              </h2>
              <br />
              <br />
            </div>
            <div className="col-lg-8">
              <p className="blog-text">
                Las redes neuronales son modelos matemáticos inspirados en el
                cerebro humano, utilizados en el campo de la inteligencia
                artificial. Se componen de tres partes principales: las capas de
                entrada, las capas ocultas y la capa de salida.
              </p>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  loading="lazy"
                  style={{ width: "50%" }}
                  className="img-blog"
                  alt="red-neuronal1-solutionops-desarrollo-de-software-javascript"
                  src={"/static/img/blog/red-neuronal1-solutionops.png"}
                />
              </div>

              <h3 className="h3-title">Capa de entrada: </h3>
              <p className="blog-text">
                Es la primera capa de la red y recibe los datos de entrada, como
                imágenes, texto o sonido. Cada dato se representa mediante nodos
                llamados neuronas artificiales.
              </p>
              <h3 className="h3-title">Capas ocultas: </h3>
              <p className="blog-text">
                Estas capas están intermedias entre la capa de entrada y la capa
                de salida. Cada neurona en las capas ocultas procesa la
                información recibida y la envía a las siguientes capas mediante
                conexiones ponderadas.
              </p>
              <h3 className="h3-title">Capa de salida: </h3>
              <p className="blog-text">
                Es la última capa de la red y produce la respuesta final o
                predicción. Puede ser una única neurona o varias, dependiendo
                del tipo de problema.
              </p>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  loading="lazy"
                  style={{ width: "50%" }}
                  className="img-blog"
                  alt="red-neuronal2-solutionops-desarrollo-de-software-javascript"
                  src={"/static/img/blog/red-neuronal2-solutionops.png"}
                />
              </div>
              <p className="blog-text">
                El funcionamiento básico de una red neuronal implica el
                procesamiento de los datos de entrada a través de múltiples
                etapas de cálculo. Cada neurona realiza una operación que
                incluye la suma ponderada de las entradas y la aplicación de una
                función de activación, que determina si la neurona se activa o
                no. La función de activación puede ser, por ejemplo, la función
                sigmoide o la función ReLU.
              </p>
              <p className="blog-text">
                Durante el entrenamiento de la red, se ajustan los pesos y las
                conexiones entre las neuronas para minimizar la diferencia entre
                las salidas reales y las deseadas. Esto se logra mediante el uso
                de algoritmos de optimización, como el descenso del gradiente,
                que buscan encontrar los valores óptimos de los pesos para
                obtener predicciones precisas en nuevos datos.
              </p>
              <p className="blog-text">
                En resumen, una red neuronal consta de capas de entrada, capas
                ocultas y una capa de salida. Procesa los datos de entrada a
                través de múltiples etapas de cálculo, utilizando conexiones
                ponderadas y funciones de activación. Durante el entrenamiento,
                los pesos se ajustan para mejorar la precisión de las
                predicciones. Las redes neuronales son una poderosa herramienta
                en el campo de la inteligencia artificial, con aplicaciones en
                áreas como el reconocimiento de imágenes, el procesamiento del
                lenguaje natural y la conducción autónoma.
              </p>

              <p className="blog-text">
                {
                  "<solutionops-team> Autor: Fabián Pérez </solutionops-team>"
                }
              </p>

              <h3 className="h3-title">Referencias</h3>
              <p className="blog-text">
              <a href="https://www.flaticon.es/iconos-gratis/aprendizaje-automatico" title="aprendizaje automático iconos">Aprendizaje automático iconos creados por Flat Icons - Flaticon</a>
              </p>
              <p>
              <a href="https://www.flaticon.es/iconos-gratis/red-neuronal" title="red neuronal iconos">Red neuronal iconos creados por Vectors Tank - Flaticon</a>
              </p>
            </div>
            <div className="col-lg-4">
              <InlineShareButtons
                config={{
                  alignment: "center",
                  color: "social",
                  enabled: true,
                  font_size: 16,
                  networks: [
                    "whatsapp",
                    "linkedin",
                    "messenger",
                    "facebook",
                    "twitter",
                    "email",
                  ],
                  padding: 12,
                  radius: 0,
                  show_total: false,
                  size: 40,
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <Footer openWhatsapp={openWhatsapp} />
    </div>
  );
};

export default RedesNeuronalesBlogPage;
