import { useRef, useEffect } from 'react';

import Header from '../../components/Header.js';
import Footer from '../../components/Footer.js';

import {InlineShareButtons} from 'sharethis-reactjs';

const AIBlogPage = () => {

  useEffect(() => {
    document.getElementById("canonical").href = "https://solutionops.cl/categorias/desarrollo-de-software/inteligencia-artificial-y-su-tecnologia";
    document.getElementById("og_site_title").content = "Inteligencia Artificial y su Tecnología";
    document.getElementById("site_keywords").content = "IA, Tecnología, SolutionOps";
    document.getElementById("og_site_description").content = "Inteligencia Artificial y su Tecnología";
    document.getElementById("site_description").content = "Inteligencia Artificial y su Tecnología";
  }, []);

  const scrollToRef = (ref) =>  window.scrollTo({
    top: ref.current.offsetTop,
    behavior: 'smooth',
  });
  const contactRef = useRef(null);
    
  const openWhatsapp = () => {
    let url = "https://api.whatsapp.com/send/?phone=56991981647&text&app_absent=0";
    window.open(url);
  }

  const goTo = (page) => {
    window.location.assign(page)
    // let url = page;
    // window.href(url);
  }

  const openCall = () => {
    let url = "tel:0056991981647";
    window.open(url);
  }

  return (
    <div className="App">
        <Header
            goTo={goTo}
            scrollToRef={scrollToRef}
            openWhatsapp={openWhatsapp}
            openCall={openCall}
        />

        <div className="container-fluid bg-blog  bg-grey">
            <div className="container main-div-title bg-white">
                <div className="row">
                    <div className='col-md-12'>
                        <h1 className="blog-title">Inteligencia Artificial y su Tecnología</h1>
                        <br/>
                    </div>
                    <div className='col-lg-8'>
         
                            <p className='blog-text'>La inteligencia artificial (IA) se refiere a la capacidad de las máquinas para realizar tareas que normalmente requieren inteligencia humana. Utilizando algoritmos y modelos matemáticos, la IA puede analizar datos, aprender patrones, tomar decisiones y resolver problemas de manera autónoma. La IA abarca una amplia gama de aplicaciones, desde asistentes virtuales y sistemas de recomendación hasta vehículos autónomos y diagnósticos médicos. Su objetivo principal es replicar y mejorar las capacidades cognitivas humanas, permitiendo a las máquinas procesar información, adaptarse al entorno y mejorar su rendimiento con el tiempo.</p>
                            <img
                                loading="lazy"
                                width="auto"
                                height="auto"
                                className="img-blog"
                                alt="ai-solutionops-1"
                                src={"/static/img/blog/ai-solutionops-1.png"}/>


                            <p className='blog-text'>Una de las tecnologías clave utilizadas para crear la inteligencia artificial es el aprendizaje automático. El aprendizaje automático es un enfoque para la programación en el que un sistema de inteligencia artificial se entrena para realizar una tarea específica utilizando ejemplos en lugar de instrucciones explícitas. Por ejemplo, un sistema de aprendizaje automático podría ser entrenado para reconocer imágenes de perros mostrándole miles de imágenes de perros etiquetados previamente. A medida que el sistema ve más y más ejemplos, se vuelve cada vez más preciso en la identificación de imágenes de perros.</p>

                            <img
                                loading="lazy"
                                width="auto"
                                height="auto"
                                className="img-blog"
                                alt="ai-solutionops-2"
                                src={"/static/img/blog/ai-solutionops-2.png"}/>

                            <p className='blog-text'>Otra tecnología importante utilizada en la inteligencia artificial es el procesamiento del lenguaje natural (PLN). El PLN se refiere a la capacidad de las computadoras para comprender y procesar el lenguaje humano. Esto puede incluir la traducción automática, la clasificación de texto y la generación de texto. El PLN se basa en técnicas como el análisis semántico y el análisis sintáctico para comprender el significado detrás del lenguaje.</p>

                            <img
                                loading="lazy"
                                width="auto"
                                height="auto"
                                className="img-blog"
                                alt="ai-solutionops-3"
                                src={"/static/img/blog/ai-solutionops-3.png"}/>

                            <p className='blog-text'>En resumen, la inteligencia artificial es la capacidad de las computadoras y otros sistemas para realizar tareas que normalmente requerirían inteligencia humana. Las tecnologías utilizadas para crear la inteligencia artificial incluyen el aprendizaje automático, el procesamiento del lenguaje natural y las redes neuronales artificiales. Estas tecnologías están impulsando la revolución de la inteligencia artificial y están cambiando la forma en que interactuamos con la tecnología en nuestro día a día.</p>

                            <p className='blog-text'>{'<solutionops-team> Autor: Jonathan Urrutia </solutionops-team>'}</p>

                    </div>
                    <div className='col-lg-4'>
                        <InlineShareButtons
                            config={{
                                alignment: 'center',
                                color: 'social',
                                enabled: true,
                                font_size: 16,
                                networks: [
                                'whatsapp',
                                'linkedin',
                                'messenger',
                                'facebook',
                                'twitter',
                                'email'
                                ],
                                padding: 12,
                                radius: 0,
                                show_total: false,
                                size: 40
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>

        <Footer
            openWhatsapp={openWhatsapp}
        />
    </div>
  );
}

export default AIBlogPage;
