import { useRef, useEffect } from 'react';

import Header from '../../components/Header.js';
import Footer from '../../components/Footer.js';

import {InlineShareButtons} from 'sharethis-reactjs';

const DatesInJsBlogPage = () => {

  useEffect(() => {
    document.getElementById("canonical").href = "https://solutionops.cl/categorias/desarrollo-de-software/como-usar-fechas-en-node-js";
    document.getElementById("og_site_title").content = "¿Cómo gestionar fechas en JavaScript/NodeJS?";
    document.getElementById("site_keywords").content = "Fechas Javascript, Fechas NodeJS, Date Javascript, Date NodeJS";
    document.getElementById("og_site_description").content = "Métodos de la clase Date para obtención de los diferentes segmentos de la misma";
    document.getElementById("site_description").content = "Métodos de la clase Date para obtención de los diferentes segmentos de la misma";
  }, []);
    
  const scrollToRef = (ref) =>  window.scrollTo({
    top: ref.current.offsetTop,
    behavior: 'smooth',
  });
  const contactRef = useRef(null);
    
  const openWhatsapp = () => {
    let url = "https://api.whatsapp.com/send/?phone=56991981647&text&app_absent=0";
    window.open(url);
  }

  const goTo = (page) => {
    window.location.assign(page)
    // let url = page;
    // window.href(url);
  }

  const openCall = () => {
    let url = "tel:0056991981647";
    window.open(url);
  }

  return (
    <div className="App">
        <Header
            goTo={goTo}
            scrollToRef={scrollToRef}
            openWhatsapp={openWhatsapp}
            openCall={openCall}
        />

        <div className="container-fluid bg-blog  bg-grey">
            <div className="container main-div-title bg-white">
                <div className="row">
                    <div className='col-md-12'>
                        <h1 className="blog-title">¿Cómo gestionar fechas en  NodeJs JavaScript?</h1>
                        <h2 className="blog-subtitle">Clase Date en Javascript/NodeJS</h2>
                        <br/>
                    </div>
                    <div className='col-lg-8'>

                        <p className='blog-text'>La clase Date en javascript nos permite gestionar fechas a través del formato ISO 8601 y diversos métodos para la manipulación de las diferentes partes de una fecha completa. </p>

                        <p className='blog-text'>Ahora bien, el formato  ISO 8601 es un estándar ampliamente utilizado para representar fechas y horas de una forma legible y bien definida.</p>

                        <p className='blog-text'>En este formato específico:</p>

                        <p className='blog-text'>"2023-05-20" representa la fecha en formato "año-mes-día".</p>
                        <p className='blog-text'>"T" es simplemente un separador entre la parte de la fecha y la parte de la hora.</p>
                        <p className='blog-text'>"13:28:57.477" representa la hora en formato "horas:minutos:segundos.milisegundos".</p>
                        <p className='blog-text'>"Z" al final indica que la fecha y la hora están en el huso horario UTC.</p>

                        <p className='blog-text'>A través de la clase Date podemos acceder a los diversos métodos: </p>
                        <pre>
                                <code>
                                    {`
const fecha = new Date();
const año = fecha.getFullYear(); // Obtiene el año (ejemplo: 2023)
const mes = fecha.getMonth(); // Obtiene el mes (0 para enero, 11 para diciembre)
const dia = fecha.getDate(); // Obtiene el día del mes (1-31)
const hora = fecha.getHours(); // Obtiene la hora (0-23)
const minutos = fecha.getMinutes(); // Obtiene los minutos (0-59)
const segundos = fecha.getSeconds(); // Obtiene los segundos (0-59)
                                    `}
                                </code>
                        </pre>

                        <p className='blog-text'>Por lo que a continuación presentaremos un segmento de código que permita entender como generar un formato de tipo yyyy-mm-dd:</p>

                            <pre>
                                <code>
                                    {`
const formatDate = (d = null) => {
    const current_date = new Date(d);

    const year = current_date.getFullYear();
    const month = String(current_date.getMonth() + 1).padStart(2, '0');
    const day = String(current_date.getDate()).padStart(2, '0');

    const formatted_date = year+"-"+month+"-"+day;

    return formatted_date;
}

const resp = formatDate("2020-04-17T13:28:57.477Z");
console.log("Fecha formateada: ", resp);

                                    `}
                                </code>
                            </pre>

                        <p className='blog-text'>En este ejemplo, getFullYear() devuelve el año actual, getMonth() devuelve el mes actual (teniendo en cuenta que los meses empiezan en 0) y getDate() devuelve el día del mes actual. Luego, utilizamos String() para convertir el mes y el día en cadenas y padStart() para asegurarnos de que tengan dos dígitos, agregando un cero inicial si es necesario. Por último, concatenamos los componentes de la fecha utilizando guiones para obtener el formato "yyyy-mm-dd".</p>

                            
                    </div>
                    <div className='col-lg-4'>
                        <InlineShareButtons
                            config={{
                                alignment: 'center',
                                color: 'social',
                                enabled: true,
                                font_size: 16,
                                networks: [
                                'whatsapp',
                                'linkedin',
                                'messenger',
                                'facebook',
                                'twitter',
                                'email'
                                ],
                                padding: 12,
                                radius: 0,
                                show_total: false,
                                size: 40
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>

        <Footer
            openWhatsapp={openWhatsapp}
        />
    </div>
  );
}

export default DatesInJsBlogPage;
