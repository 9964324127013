import { useRef, useEffect } from "react";

import Header from "../../components/Header.js";
import Footer from "../../components/Footer.js";

import { InlineShareButtons } from "sharethis-reactjs";

const FuntionesAlmacenadasBlogPage = () => {
  useEffect(() => {
    document.getElementById("canonical").href =
      "https://solutionops.cl/categorias/desarrollo-de-software/funciones-almacenadas";
    document.getElementById("og_site_title").content =
      "Funciones almacenadas en MYSQL";
    document.getElementById("site_keywords").content =
      "Mejorando la eficiencia y modularidad de tus consultas SQL";
    document.getElementById("og_site_description").content =
      "Mejorando la eficiencia y modularidad de tus consultas SQL";
    document.getElementById("site_description").content =
      "Mejorando la eficiencia y modularidad de tus consultas SQL";
  }, []);

  const scrollToRef = (ref) =>
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: "smooth",
    });
  const contactRef = useRef(null);

  const openWhatsapp = () => {
    let url =
      "https://api.whatsapp.com/send/?phone=56991981647&text&app_absent=0";
    window.open(url);
  };

  const goTo = (page) => {
    window.location.assign(page);
    // let url = page;
    // window.href(url);
  };

  const openCall = () => {
    let url = "tel:0056991981647";
    window.open(url);
  };

  return (
    <div className="App">
      <Header
        goTo={goTo}
        scrollToRef={scrollToRef}
        openWhatsapp={openWhatsapp}
        openCall={openCall}
      />

      <div className="container-fluid bg-blog  bg-grey">
        <div className="container main-div-title bg-white">
          <div className="row">
            <div className="col-md-12">
              <h1 className="blog-title">Funciones almacenadas en MySQL</h1>
              <h2 className="blog-subtitle">
                Mejorando la eficiencia y modularidad de tus consultas SQL
              </h2>
              <br />
              <br />
            </div>
            <div className="col-lg-8">
              <p className="blog-text">
                Una función almacenada, también conocida como simplemente
                función, es un conjunto de instrucciones o bloques de código SQL
                que se almacenan en una base de datos y pueden ser invocados o
                llamados en diferentes momentos para realizar tareas
                específicas. Puede recibir cero o varios parámetros y siempre
                retorna un valor como resultado. Normalmente, se utiliza
                mediante una sentencia SELECT o dentro de una expresión.
              </p>
              <p className="blog-text">
                Para crear una función almacenada se utiliza la sentencia CREATE
                FUNCTION. A continuación, se presenta un ejemplo de función
                creada en MySQL, la cual retornará la cantidad de días hábiles
                entre dos fechas (total de días sin contar fin de semana).
              </p>
              <pre>
                <code>
                  {`
DELIMITER //

CREATE FUNCTION CalcularDiasHabiles(fechaInicio DATE, fechaFin DATE)
RETURNS INT
NO SQL
BEGIN
    DECLARE diasHabiles INT DEFAULT 0;
    DECLARE fechaActual DATE;

    SET fechaActual = fechaInicio;

    WHILE fechaActual <= fechaFin DO
        IF WEEKDAY(fechaActual) NOT IN (5, 6) THEN
            SET diasHabiles = diasHabiles + 1;
        END IF;

        SET fechaActual = fechaActual + INTERVAL 1 DAY;
    END WHILE;

    RETURN diasHabiles;
END //

DELIMITER ;
                                    `}
                </code>
              </pre>
              <p className="blog-text">
                Como se puede observar, una función almacenada contiene
                múltiples elementos que pasaremos a detallar a continuación:
              </p>
              <ul className="blog-text">
                <li>
                  &#x2018;DELIMITER //&#x2019; se utiliza para cambiar el
                  delimitador de sentencias a // en lugar del punto y coma (;)
                  predeterminado, para permitir que la función almacenada
                  contenga sentencias múltiples.
                </li>
                <li>
                  &#x2018;CREATE FUNCTION CalcularDiasHabiles(fechaInicio DATE,
                  fechaFin DATE)&#x2019; crea la función almacenada llamada
                  "CalcularDiasHabiles" que toma dos parámetros: "fechaInicio" y
                  "fechaFin" de tipo DATE.
                </li>
                <li>
                  &#x2018;RETURNS INT&#x2019; indica que la función retornará un
                  valor entero.
                </li>
                <li>
                  &#x2018;NO SQL&#x2019; indica que la función no accede a la
                  base de datos ni modifica datos.
                </li>
                <li>
                  &#x2018;BEGIN&#x2019; marca el comienzo del bloque de código
                  de la función.
                </li>
                <li>
                  &#x2018;DECLARE diasHabiles INT DEFAULT 0;&#x2019; declara una
                  variable llamada "diasHabiles" de tipo entero y la inicializa
                  en 0.
                </li>
                <li>
                  &#x2018;DECLARE fechaActual DATE;&#x2019; declara una variable
                  llamada "currentDate" de tipo DATE para almacenar la fecha
                  actual durante el bucle.
                </li>
                <li>
                  &#x2018;SET fechaActual = fechaInicio;&#x2019; asigna el valor
                  de "fechaInicio" a la variable "currentDate".
                </li>
                <li>
                  &#x2018;WHILE fechaActual &le; fechaFin DO&#x2019; establece
                  un bucle mientras "currentDate" sea menor o igual que
                  "fechaFin".
                </li>
                <li>
                  &#x2018;IF WEEKDAY(fechaActual) NOT IN (5, 6) THEN&#x2019;
                  verifica si el día de la semana de "fechaActual " no es sábado
                  (5) ni domingo (6).
                </li>
                <li>
                  &#x2018;SET diasHabiles = diasHabiles + 1;&#x2019; incrementa
                  en 1 el valor de la variable "diasHabiles" si el día actual no
                  es fin de semana.
                </li>
                <li>
                  &#x2018;SET currentDate = currentDate + INTERVAL 1
                  DAY;&#x2019; incrementa la fecha actual en un día.
                </li>
                <li>&#x2018;END WHILE;&#x2019; marca el fin del bucle.</li>
                <li>
                  &#x2018;RETURN diasHabiles;&#x2019; devuelve el valor de la
                  variable "diasHabiles".
                </li>
                <li>
                  &#x2018;END //&#x2019; marca el fin del bloque de código de la
                  función.
                </li>
                <li>
                  &#x2018;DELIMITER ;&#x2019; restaura el delimitador de
                  sentencias predeterminado (;).
                </li>
              </ul>
              <p className="blog-text">
                Para ejecutar la función almacenada, esta se debe llamar de la
                siguiente forma:
              </p>
              <pre>
                <code>
                  {`
SELECT CalcularDiasHabiles('2023-06-01', '2023-06-30');
                                    `}
                </code>
              </pre>

              <p className="blog-text">Lo que devolverá un total de 22 días.</p>
              <p className="blog-text">
                {
                  "<solutionops-team> Autor: Jonathan Urrutia </solutionops-team>"
                }
              </p>
            </div>
            <div className="col-lg-4">
              <InlineShareButtons
                config={{
                  alignment: "center",
                  color: "social",
                  enabled: true,
                  font_size: 16,
                  networks: [
                    "whatsapp",
                    "linkedin",
                    "messenger",
                    "facebook",
                    "twitter",
                    "email",
                  ],
                  padding: 12,
                  radius: 0,
                  show_total: false,
                  size: 40,
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <Footer openWhatsapp={openWhatsapp} />
    </div>
  );
};

export default FuntionesAlmacenadasBlogPage;
