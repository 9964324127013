import { useRef, useEffect } from 'react';

import Header from '../../components/Header.js';
import Footer from '../../components/Footer.js';

import { InlineShareButtons } from 'sharethis-reactjs';

const AxiosFetchBlogPage = () => {

  useEffect(() => {
    document.getElementById("canonical").href = "https://solutionops.cl/categorias/desarrollo-de-software/axios-vs-fetch";
    document.getElementById("og_site_title").content = "Axios vs Fetxh";
    document.getElementById("site_keywords").content = "Comparación de Axios y Fetch";
    document.getElementById("og_site_description").content = "Comparación de Axios y Fetch";
    document.getElementById("site_description").content = "Comparación de Axios y Fetch";
  }, []);

  const scrollToRef = (ref) => window.scrollTo({
    top: ref.current.offsetTop,
    behavior: 'smooth',
  });
  const contactRef = useRef(null);

  const openWhatsapp = () => {
    let url = "https://api.whatsapp.com/send/?phone=56991981647&text&app_absent=0";
    window.open(url);
  }

  const goTo = (page) => {
    window.location.assign(page)
    // let url = page;
    // window.href(url);
  }

  const openCall = () => {
    let url = "tel:0056991981647";
    window.open(url);
  }

  return (
    <div className="App">
      <Header
        goTo={goTo}
        scrollToRef={scrollToRef}
        openWhatsapp={openWhatsapp}
        openCall={openCall}
      />

      <div className="container-fluid bg-blog  bg-grey">
        <div className="container main-div-title bg-white">
          <div className="row">
            <div className='col-md-12'>
              <h1 className="blog-title">Axios v/s Fetch</h1>
              <h2 className="blog-subtitle">Comparación entre Axios y Fetch: ¿Cuál es la mejor opción para tus solicitudes HTTP?</h2>
              <br />
            </div>
            <div className='col-lg-8'>
              <h3 className='h3-title'>¿Qué es Axios y como realizar una petición?</h3>
              <p className='blog-text'>Axios es una biblioteca de JavaScript que se utiliza para realizar solicitudes HTTP desde un navegador web o desde Node.js. Es una herramienta muy popular y ampliamente utilizada para interactuar con APIs y realizar operaciones de red en aplicaciones web.
                Axios proporciona una interfaz fácil de usar para realizar solicitudes HTTP, ya sea para enviar datos al servidor o para recibir datos del servidor. Admite tanto los métodos GET como POST, así como otros métodos HTTP, y permite personalizar y configurar las solicitudes según las necesidades específicas del proyecto.
              </p>
              {/* <h3 className='h3-title'>1. Ejecuta el siguiente comando para instalar Vuetify “npm install vuetify@next”</h3> */}
              {/* <h3 className='h3-title'>2. importa Vuetify en tu archivo main.js (o en el archivo principal de tu aplicación)</h3> */}

              <img
                loading="lazy"
                width="auto"
                height="auto"
                className="img-blog"
                alt="axios-solutionops-desarrollo-de-software-javascript"
                src={"/static/img/blog/axios-vs-fetch-solutionops.png"} />
              <h3 className='h3-title'>¿Qué es Fetch y como realizar una petición?</h3>
              <p className='blog-text'>Fetch es una API de JavaScript que proporciona una forma de realizar peticiones HTTP (solicitud y respuesta) desde un navegador web o desde un entorno Node.js. Es una forma moderna y más flexible de realizar solicitudes a servidores web en comparación con las técnicas tradicionales como XMLHttpRequest.
                Para realizar una petición con Fetch, puedes utilizar la siguiente estructura básica:
              </p>
              <img
                loading="lazy"
                width="auto"
                height="auto"
                className="img-blog"
                alt="fetch-solutionops-desarrollo-de-software-javascript"
                src={"/static/img/blog/axios-vs-fetch2-solutionops.png"} />

              <p className='blog-text'>En última instancia, la elección entre Axios y Fetch dependerá de las necesidades específicas de tu proyecto y las preferencias personales del equipo de desarrollo. Si buscas una biblioteca con una API más intuitiva, manejo de errores avanzado y mayor compatibilidad con navegadores más antiguos, Axios puede ser una opción más adecuada. Por otro lado, si buscas una solución más liviana y no tienes que preocuparte por la compatibilidad con versiones antiguas de IE, Fetch podría ser una opción a considerar. En cualquier caso, ambas herramientas son capaces de realizar solicitudes HTTP eficientemente y son ampliamente utilizadas en la comunidad de desarrollo web.</p>
              <p className='blog-text'>{'<solutionops-team> Autor: Ramon Duran </solutionops-team>'}</p>

            </div>
            <div className='col-lg-4'>
              <InlineShareButtons
                config={{
                  alignment: 'center',
                  color: 'social',
                  enabled: true,
                  font_size: 16,
                  networks: [
                    'whatsapp',
                    'linkedin',
                    'messenger',
                    'facebook',
                    'twitter',
                    'email'
                  ],
                  padding: 12,
                  radius: 0,
                  show_total: false,
                  size: 40
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <Footer
        openWhatsapp={openWhatsapp}
      />
    </div>
  );
}

export default AxiosFetchBlogPage;
