import { useRef, useEffect } from 'react';

import Header from '../../components/Header.js';
import Footer from '../../components/Footer.js';

import {InlineShareButtons} from 'sharethis-reactjs';

const StatePromisesBlogPage = () => {

  useEffect(() => {
    document.getElementById("canonical").href = "https://solutionops.cl/categorias/desarrollo-de-software/tipos-de-estados-para-promesa-en-node-js";
    document.getElementById("og_site_title").content = "Estados de una promesa - NodeJs";
    document.getElementById("site_keywords").content = "Estados de promesas, NodeJS, Javascript, Asincrono, Sincrono";
    document.getElementById("og_site_description").content = "Tipos de estado en las promesas de javascript Node JS";
    document.getElementById("site_description").content = "Tipos de estado en las promesas de javascript Node JS";
  }, []);
    
  const scrollToRef = (ref) =>  window.scrollTo({
    top: ref.current.offsetTop,
    behavior: 'smooth',
  });
  const contactRef = useRef(null);
    
  const openWhatsapp = () => {
    let url = "https://api.whatsapp.com/send/?phone=56991981647&text&app_absent=0";
    window.open(url);
  }

  const goTo = (page) => {
    window.location.assign(page)
    // let url = page;
    // window.href(url);
  }

  const openCall = () => {
    let url = "tel:0056991981647";
    window.open(url);
  }

  return (
    <div className="App">
        <Header
            goTo={goTo}
            scrollToRef={scrollToRef}
            openWhatsapp={openWhatsapp}
            openCall={openCall}
        />

        <div className="container-fluid bg-blog  bg-grey">
            <div className="container main-div-title bg-white">
                <div className="row">
                    <div className='col-md-12'>
                        <h1 className="blog-title">Estados de una promesa - NodeJs JavaScript</h1>
                        <h2 className="blog-subtitle">Pendiente (Pending) / Resulta (Fulfilled) / Rechazada (Rejected)</h2>
                        <br/>
                    </div>
                    <div className='col-lg-8'>
                        {/* <img
                            loading="lazy"
                            width="auto"
                            height="auto"
                            className="img-blog"
                            alt="async-await-solutionops-desarrollo-de-software-javascript"
                            src={"/static/img/blog/async-await-solutionops-desarrollo-de-software-javascript.png"}/> */}

                            <p className='blog-text'>En la programación asincrónica de JavaScript, las promesas son una función esencial. Su uso permite a los programadores escribir código que maneja eventos futuros sin bloquear el hilo principal de ejecución. En esencia, una promesa representa un valor que puede no estar disponible en el momento en que se crea. En este artículo, se abordarán los distintos estados que puede tener una promesa en JavaScript, así como la forma correcta de manejarlos.</p>
                            <p className='blog-text'>Una promesa en JavaScript puede estar en uno de los siguientes tres estados:</p>
                            <p className='blog-text'>1) Pendiente: esto ocurre cuando la promesa es creada y está a la espera de ser resuelta o rechazada.</p>
                            <p className='blog-text'>2) Resuelta: una promesa se encuentra en este estado cuando la operación que realizó ha sido completada con éxito. En este caso, la promesa tendrá un valor asignado que es pasado como argumento a la función de resolución de la promesa.</p>
                            <p className='blog-text'>3) Rechazada: una promesa se encuentra en este estado cuando la operación que realizó no ha sido completada con éxito. En este caso, la promesa tendrá un motivo asignado que es pasado como argumento a la función de rechazo de la promesa.</p>
                            <pre>
                                <code>
                                    {`
function init() {
    return new Promise(async (resolve, reject)=>{
        try {
            const request = axios.get('https://solutionops.cl').then((response)=>{
                console.log("Respuesta Resuelta: ", response.status);
                resolve("Respuesta Resuelta: " + response.status)
            });

            console.log("Respuesta Pending: ", request);

        } catch (error) {
            console.log("Respuesta de Reject: ", error);
            reject("Respuesta de Reject: " + error)
        }
    });
    
}

init();

                                    `}
                                </code>
                            </pre>

                            <p className='blog-text'>Por ejemplo, en el código anterior se ejecuta un request HTTP hacia el home del sitio de solutionops.cl, el mismo debe retornar una respuesta de código 200, sin embargo por cómo se ejecuta el código de javascript primero termina imprimiendo el valor de la variable request, la cuál por no haber sido resuelta retorna como Pending, por otra parte al momento de obtener una respuesta se dirijirá al then y se imprimirá el resultado del código de respuesta satisfactoriamente generando la siguiente respuesta en la consola/terminal:</p>
                            <pre>
                                <code>
                                    {`
Respuesta Pending:  Promise { <pending> }
Respuesta Resuelta:  200
                                    `}
                                </code>
                            </pre>

                            <p className='blog-text'>En conclusión, las promesas son una característica clave de la programación asincrónica en JavaScript. Al comprender los diferentes estados de las promesas, podemos escribir un código más eficiente y manejar adecuadamente las respuestas de la API. Asegúrate de siempre manejar los estados de las promesas adecuadamente y capturar los errores de manera adecuada.</p>
                            <p className='blog-text'>{'<solutionops-team> Happy Coding! </solutionops-team>'}</p>
                            
                    </div>
                    <div className='col-lg-4'>
                        <InlineShareButtons
                            config={{
                                alignment: 'center',
                                color: 'social',
                                enabled: true,
                                font_size: 16,
                                networks: [
                                'whatsapp',
                                'linkedin',
                                'messenger',
                                'facebook',
                                'twitter',
                                'email'
                                ],
                                padding: 12,
                                radius: 0,
                                show_total: false,
                                size: 40
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>

        <Footer
            openWhatsapp={openWhatsapp}
        />
    </div>
  );
}

export default StatePromisesBlogPage;
