import { useRef, useEffect } from 'react';

import Header from '../../components/Header.js';
import Footer from '../../components/Footer.js';

import {InlineShareButtons} from 'sharethis-reactjs';

const HooksBlogPage = () => {

  useEffect(() => {
    document.getElementById("canonical").href = "https://solutionops.cl/categorias/desarrollo-de-software/react-hooks-mas-usados";
    document.getElementById("og_site_title").content = "Hooks mas usados en React";
    document.getElementById("site_keywords").content = "React Hooks, useEffect, useRef, useState, Javascript, Node Js, React Js";
    document.getElementById("og_site_description").content = "Manejo de estados en componentes funcionales de React JS (useEffect, useState, useRef)";
    document.getElementById("site_description").content = "Manejo de estados en componentes funcionales de React JS (useEffect, useState, useRef)";
  }, []);

  const scrollToRef = (ref) =>  window.scrollTo({
    top: ref.current.offsetTop,
    behavior: 'smooth',
  });
  const contactRef = useRef(null);
    
  const openWhatsapp = () => {
    let url = "https://api.whatsapp.com/send/?phone=56991981647&text&app_absent=0";
    window.open(url);
  }

  const goTo = (page) => {
    window.location.assign(page)
    // let url = page;
    // window.href(url);
  }

  const openCall = () => {
    let url = "tel:0056991981647";
    window.open(url);
  }

  return (
    <div className="App">
        <Header
            goTo={goTo}
            scrollToRef={scrollToRef}
            openWhatsapp={openWhatsapp}
            openCall={openCall}
        />

        <div className="container-fluid bg-blog  bg-grey">
            <div className="container main-div-title bg-white">
                <div className="row">
                    <div className='col-md-12'>
                        <h1 className="blog-title">Hooks Más Usados en React JS</h1>
                        <h2 className="blog-subtitle">useEffect / useState / useRef</h2>
                        <br/>
                    </div>
                    <div className='col-lg-8'>
         

                            <p className='blog-text'>Los hooks de React son una herramienta fundamental para desarrollar aplicaciones modernas y eficientes. Entre ellos, destacan el useState(), que permite manejar estados en componentes funcionales, el useEffect(), que permite trabajar con efectos secundarios en la aplicación, y el useRef(), que permite hacer referencia a elementos del DOM. Juntos, estos hooks facilitan la creación de aplicaciones dinámicas y escalables en React.</p>

                            <h3 className='h3-title'>useState</h3>


                            <p className='blog-text'>Supongamos que queremos mostrar un contador que aumente en uno cada vez que usuario hace click en un botón.</p>
                            <p className='blog-text'>Podriamos hacer utilizar el hook useState() de la sigiente manera.</p>

                            <img
                                loading="lazy"
                                width="auto"
                                height="auto"
                                className="img-blog"
                                alt="useState-hook-react"
                                src={"/static/img/blog/useState-hook-react.png"}/>

                            <p className='blog-text'>En este ejemplo, creamos un estado count y una función setCount para actualizar ese estado.Luego, en la función Contador, mostramos el valor actual de count en una etiqueta  p y agregamos un botón que llama la función setCount cada vez que se hace click , aumentando asi el valor de count.</p>
                         
                            <h3 className='h3-title'>useEffect</h3>
                            <p className='blog-text'>En este ejemplo, useEffect se utiliza para actualizar el título del documento cada vez que el usuario hace clic en el botón. La función que se pasa como argumento a useEffect se ejecuta después de que se renderiza el componente. En este caso, la función actualiza el título del documento utilizando la plantilla de cadena de texto que incluye la cantidad de clics que ha realizado el usuario.</p>
                            
                            <img
                                loading="lazy"
                                width="auto"
                                height="auto"
                                className="img-blog"
                                alt="useEffect-hook-react"
                                src={"/static/img/blog/useEffect-hook-react.png"}/>
                            
                            
                            <h3 className='h3-title'>useReff</h3>
                            <p className='blog-text'>En este ejemplo, useRef se utiliza para obtener una referencia al elemento input en el DOM. Luego, se utiliza la referencia en la función handleClick para establecer el foco en el elemento input cuando el usuario hace clic en el botón. La función useRef devuelve un objeto con una propiedad current que se puede utilizar para almacenar cualquier valor mutable. En este caso, se almacena una referencia al elemento input en la propiedad current.</p>
                            
                            <img
                                loading="lazy"
                                width="auto"
                                height="auto"
                                className="img-blog"
                                alt="useRef-hook-react"
                                src={"/static/img/blog/useRef-hook-react.png"}/>
                            
                            
                            <p className='blog-text'>{'<solutionops-team> Autor: Ramón Durán </solutionops-team>'}</p>

                    </div>
                    <div className='col-lg-4'>
                        <InlineShareButtons
                            config={{
                                alignment: 'center',
                                color: 'social',
                                enabled: true,
                                font_size: 16,
                                networks: [
                                'whatsapp',
                                'linkedin',
                                'messenger',
                                'facebook',
                                'twitter',
                                'email'
                                ],
                                padding: 12,
                                radius: 0,
                                show_total: false,
                                size: 40
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>

        <Footer
            openWhatsapp={openWhatsapp}
        />
    </div>
  );
}

export default HooksBlogPage;
