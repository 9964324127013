import { useRef, useEffect } from "react";

import Header from "../../components/Header.js";
import Footer from "../../components/Footer.js";

import { InlineShareButtons } from "sharethis-reactjs";

const DockerAppNodeBlogPage = () => {
  useEffect(() => {
    document.getElementById("canonical").href =
      "https://solutionops.cl/categorias/desarrollo-de-software/dockerizar-node";
    document.getElementById("og_site_title").content =
      "Como Dockerizar un proyecto de Nodejs";
    document.getElementById("site_keywords").content =
      "Dockerizando Node.js: Empaquetando tu Proyecto de Node.js en un Contenedor";
    document.getElementById("og_site_description").content =
      "Dockerizando Node.js: Empaquetando tu Proyecto de Node.js en un Contenedor";
    document.getElementById("site_description").content =
      "Dockerizando Node.js: Empaquetando tu Proyecto de Node.js en un Contenedor";
  }, []);

  const scrollToRef = (ref) =>
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: "smooth",
    });
  const contactRef = useRef(null);

  const openWhatsapp = () => {
    let url =
      "https://api.whatsapp.com/send/?phone=56991981647&text&app_absent=0";
    window.open(url);
  };

  const goTo = (page) => {
    window.location.assign(page);
    // let url = page;
    // window.href(url);
  };

  const openCall = () => {
    let url = "tel:0056991981647";
    window.open(url);
  };

  return (
    <div className="App">
      <Header
        goTo={goTo}
        scrollToRef={scrollToRef}
        openWhatsapp={openWhatsapp}
        openCall={openCall}
      />

      <div className="container-fluid bg-blog  bg-grey">
        <div className="container main-div-title bg-white">
          <div className="row">
            <div className="col-md-12">
              <h1 className="blog-title">
                Controlando el Acceso a Recursos Externos: Una Guía Completa
                sobre CORS en Node.js
              </h1>
              <h2 className="blog-subtitle">
                Dockerizando Node.js: Empaquetando tu Proyecto de Node.js en un
                Contenedor
              </h2>
              <br />
              <br />
            </div>
            <div className="col-lg-8">
              <ol className="blog-text">
                <li>
                  Crea un archivo llamado Dockerfile en la raíz de tu proyecto.
                  Este archivo contendrá las instrucciones para construir la
                  imagen de Docker.
                </li>
                <li>
                  Abre el archivo Dockerfile en un editor de texto y configura
                  tu dockerfile.
                  <br />
                  <pre>
                <code>
                  {`
# Establece la imagen base
FROM node:16

# Establece el directorio de trabajo dentro del contenedor
WORKDIR /app

# Copia el archivo package.json y package-lock.json a /app
COPY package*.json ./

# Instala las dependencias del proyecto
RUN npm install

# Copia el resto del código fuente al directorio de trabajo actual
COPY . .

# Expone el puerto que tu aplicación Node.js utiliza
EXPOSE 3000

# Comando para ejecutar tu aplicación cuando se inicie el contenedor

CMD ["node", "app.js"]
                                    `}
                </code>
              </pre>
                  
                </li>
                <li>Guarda y cierra el archivo Dockerfile.</li>
                <li>
                  Abre una terminal en el directorio raíz de tu proyecto y
                  ejecuta el siguiente comando para construir la imagen de
                  Docker:
                  <br />
                  <code>docker build -t nombre_imagen .</code>
                </li>
                <li>
                  Reemplaza "nombre_imagen" con el nombre que quieras asignarle
                  a tu imagen de Docker. El punto al final del comando indica
                  que el contexto de construcción es el directorio actual.
                </li>
                <li>
                  Una vez que la imagen se haya construido correctamente, puedes
                  ejecutar un contenedor basado en esa imagen con el siguiente
                  comando:
                  <br />
                  <code>docker run -p 3000:3000 nombre_imagen</code>
                </li>
              </ol>
              <p className="blog-text">
                Esto ejecutará el contenedor y redirigirá el puerto 3000 del
                contenedor al puerto 3000 de tu máquina host. ¡Listo! Ahora tu
                proyecto de Node.js está dockerizado y se puede ejecutar en un
                contenedor de Docker.
              </p>

              <p className="blog-text">
                {"<solutionops-team> Autor: Ramon Duran </solutionops-team>"}
              </p>
            </div>
            <div className="col-lg-4">
              <InlineShareButtons
                config={{
                  alignment: "center",
                  color: "social",
                  enabled: true,
                  font_size: 16,
                  networks: [
                    "whatsapp",
                    "linkedin",
                    "messenger",
                    "facebook",
                    "twitter",
                    "email",
                  ],
                  padding: 12,
                  radius: 0,
                  show_total: false,
                  size: 40,
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <Footer openWhatsapp={openWhatsapp} />
    </div>
  );
};

export default DockerAppNodeBlogPage;
