const TechServices360 = () => {
  return (
    <div className="container-fluid px-0 dark-background-rocket hght250">
      <div className="row no-gutters pdng5perct">
        <div className="col-md-8">
          <h2 className="white-text">SERVICIOS TECNOLOGICOS</h2>
          <p className="p-text-white">En SolutionOps creamos soluciones de software a medida. Ofrecemos servicios completos de desarrollo de software en múltiples plataformas y lenguajes de programación. Contáctenos hoy para alcanzar sus objetivos de negocio.</p>
        </div>
        <div className="col-md-4"></div>
      </div>
    </div>
  )
}

export default TechServices360;
