import { useRef, useEffect } from 'react';

import Header from '../../components/Header.js';
import Footer from '../../components/Footer.js';

import { InlineShareButtons } from 'sharethis-reactjs';

const PfxCertBlogPage = () => {

    useEffect(() => {
        document.getElementById("canonical").href = "https://solutionops.cl/categorias/desarrollo-de-software/exportar-certificado-y-claves-de-un-archivo-pfx-con-php";
        document.getElementById("og_site_title").content = "Exportar Certificado y Claves de un archivo .pfx con PHP";
        document.getElementById("site_keywords").content = "PFX, php, exportar certificado, exportar claves";
        document.getElementById("og_site_description").content = "Exportar Certificado y Claves de un archivo .pfx con PHP";
        document.getElementById("site_description").content = "Exportar Certificado y Claves de un archivo .pfx con PHP";
    }, []);

    const scrollToRef = (ref) => window.scrollTo({
        top: ref.current.offsetTop,
        behavior: 'smooth',
    });
    const contactRef = useRef(null);

    const openWhatsapp = () => {
        let url = "https://api.whatsapp.com/send/?phone=56991981647&text&app_absent=0";
        window.open(url);
    }

    const goTo = (page) => {
        window.location.assign(page)
        // let url = page;
        // window.href(url);
    }

    const openCall = () => {
        let url = "tel:0056991981647";
        window.open(url);
    }

    return (
        <div className="App">
            <Header
                goTo={goTo}
                scrollToRef={scrollToRef}
                openWhatsapp={openWhatsapp}
                openCall={openCall}
            />

            <div className="container-fluid bg-blog  bg-grey">
                <div className="container main-div-title bg-white">
                    <div className="row">
                        <div className='col-md-12'>
                            <h1 className="blog-title">Exportar Certificado y Claves de un archivo .pfx con PHP</h1>
                            <h2 className="blog-subtitle">PHP / PFX / OPENSSL</h2>
                            <br />
                        </div>
                        <div className='col-lg-8'>

                            <p className='blog-text'>En PHP, OpenSSL se utiliza para manipular certificados digitales y proporcionar seguridad en aplicaciones web. Aquí te mostraremos cómo extraer certificados digitales usando OpenSSL en PHP.</p>
                            <p className='blog-text'>Antes de empezar, asegúrate de tener habilitado OpenSSL en tu versión de PHP, solo tienes que cambiar en el archivo php.ini lo siguiente:</p>
                            <p className='blog-text' style={{ fontStyle: "italic", fontWeight: 300 }}>“;extension=openssl” a “extension=openssl”.</p>

                            <p className='blog-text'>Una vez habilitado, puedes empezar a utilizar OpenSSL en PHP. Para extraer un certificado digital, debes seguir los siguientes pasos:</p>

                            <p className='blog-text'><strong>Paso 1:</strong> Cargar el certificado digital. </p>
                            <p className='blog-text'>Puedes hacerlo utilizando la función openssl_x509_read(). Esta función lee el certificado digital desde un archivo o desde una cadena.</p>
                            <pre>
                                <code>
                                    {`
$certificate_content = file_get_contents('/path/to/certificate.pfx'); 
                                    `}
                                </code>
                            </pre>

                            <p className='blog-text'><strong>Paso 2:</strong> Extraer certificado y llave privada</p>
                            <p className='blog-text'>Para esto utilizaremos la función openssl_pkcs12_read() el cual recibe como parámetro el contenido del archivo .pfx (que cargamos en el paso 1) y la contraseña del archivo.</p>


                            <pre>
                                <code>
                                    {`
if (openssl_pkcs12_read($certificate_content, $data, "password")) {
    echo "Información del certificado\n";
    print_r($data);
} else {
    echo "Error: No se puede extraer datos.\n";
    exit;
}
                                    `}
                                </code>
                            </pre>

                            <p className='blog-text'><strong>Paso 3:</strong> Obtener la clave pública del certificado </p>
                            <p className='blog-text'>Además de la información del certificado, también puedes obtener la clave pública del mismo. </p>

                            <pre>
                                <code>
                                    {`
$details = openssl_pkey_get_details(openssl_pkey_get_private($data['pkey']));

$public_key = $details['key'];

                                    `}
                                </code>
                            </pre>

                            <p className='blog-text'>Adicionalmente podemos extraer el Modulo y el Exponente:</p>

                            <pre>
                                <code>
                                    {`
$modulus = base64_encode($details['rsa']['n']);

$exponent = base64_encode($details['rsa']['e']);
                                    `}
                                </code>
                            </pre>

                            <p className='blog-text'>{'<solutionops-team> Autor: Jojan Cardenas </solutionops-team>'}</p>

                        </div>
                        <div className='col-lg-4'>
                            <InlineShareButtons
                                config={{
                                    alignment: 'center',
                                    color: 'social',
                                    enabled: true,
                                    font_size: 16,
                                    networks: [
                                        'whatsapp',
                                        'linkedin',
                                        'messenger',
                                        'facebook',
                                        'twitter',
                                        'email'
                                    ],
                                    padding: 12,
                                    radius: 0,
                                    show_total: false,
                                    size: 40
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <Footer
                openWhatsapp={openWhatsapp}
            />
        </div>
    );
}

export default PfxCertBlogPage;
