import { useRef, useEffect } from "react";

import Header from "../../components/Header.js";
import Footer from "../../components/Footer.js";

import { InlineShareButtons } from "sharethis-reactjs";

const GmailPhpBlogPage = () => {
  useEffect(() => {
    document.getElementById("canonical").href =
      "https://solutionops.cl/categorias/desarrollo-de-software/gmail-php";
    document.getElementById("og_site_title").content =
      "Leer Correos de Gmail con PHP (IMAP)";
    document.getElementById("site_keywords").content =
      "Cómo leer correos de Gmail utilizando PHP y el protocolo IMAP";
    document.getElementById("og_site_description").content =
      "Cómo leer correos de Gmail utilizando PHP y el protocolo IMAP";
    document.getElementById("site_description").content =
      "Cómo leer correos de Gmail utilizando PHP y el protocolo IMAP";
  }, []);

  const scrollToRef = (ref) =>
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: "smooth",
    });
  const contactRef = useRef(null);

  const openWhatsapp = () => {
    let url =
      "https://api.whatsapp.com/send/?phone=56991981647&text&app_absent=0";
    window.open(url);
  };

  const goTo = (page) => {
    window.location.assign(page);
    // let url = page;
    // window.href(url);
  };

  const openCall = () => {
    let url = "tel:0056991981647";
    window.open(url);
  };

  return (
    <div className="App">
      <Header
        goTo={goTo}
        scrollToRef={scrollToRef}
        openWhatsapp={openWhatsapp}
        openCall={openCall}
      />

      <div className="container-fluid bg-blog  bg-grey">
        <div className="container main-div-title bg-white">
          <div className="row">
            <div className="col-md-12">
              <h1 className="blog-title">
                Leer Correos de Gmail con PHP (IMAP)
              </h1>
              <h2 className="blog-subtitle">
                Entendiendo las diferencias clave en la configuración de tu
                infraestructura web
              </h2>
              <br />
              <br />
            </div>
            <div className="col-lg-8">
              <p className="blog-text">
                En PHP existe un módulo llamado IMAP (Internet Message Access
                Protocol) dedicado a las conexiones a correos, este módulo
                contiene un conjunto de funciones que nos ayudan a realizar esta
                tarea fácilmente.
              </p>
              <p className="blog-text">
                Pero antes tenemos que cambiar las siguientes configuraciones en
                nuestra cuenta de Gmail y google para permitir a una aplicación
                externa conectarse.
              </p>
              <p className="blog-text">
                Primero debemos crear una contraseña de aplicaciones en nuestra
                cuenta de google para esto activaremos la verificación en 2
                pasos, una vez activada la verificación en 2 pasos, volvemos a
                ingresar a y creamos la contraseña de aplicaciones, en el campo
                app selecciona otro y luego deberás indicar una pequeña
                descripción y te generará una contraseña de 16 caracteres.
              </p>
              <p className="blog-text">
                La segunda configuración es para que Gmail nos permita
                conectarnos por el protocolo IMAP. Basta con que vayamos a la
                configuración de Gmail en la sección de Reenvío y correo
                POP/IMAP, buscamos la opción Acceso IMAP, marcamos Habilitar
                acceso IMAP y guardamos cambios.{" "}
              </p>
              <p className="blog-text">
                El siguiente código realiza la conexión a una cuenta de Gmail
                por medio del protocolo IMAP y a la vez obtiene los asuntos de
                todos los correos.
              </p>
              <img
                loading="lazy"
                width="auto"
                height="auto"
                className="img-blog"
                alt="gmail-php-solutionops-desarrollo-de-software-javascript"
                src={"/static/img/blog/gmail-php-solutionops.png"} />
              

              <p className="blog-text">
                {
                  "<solutionops-team> Autor: Jojan Cardenas </solutionops-team>"
                }
              </p>
            </div>
            <div className="col-lg-4">
              <InlineShareButtons
                config={{
                  alignment: "center",
                  color: "social",
                  enabled: true,
                  font_size: 16,
                  networks: [
                    "whatsapp",
                    "linkedin",
                    "messenger",
                    "facebook",
                    "twitter",
                    "email",
                  ],
                  padding: 12,
                  radius: 0,
                  show_total: false,
                  size: 40,
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <Footer openWhatsapp={openWhatsapp} />
    </div>
  );
};

export default GmailPhpBlogPage;
