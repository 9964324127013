const Services = ({
  scrollToRef,
  serviceRef,
  goTo,
}) => {

  const services = [
    // {
    //   name: "HOSTING",
    //   content: "Planes estandarizados y personalizados, nos adaptamos a sus necesidades, indíquenos sus requerimientos y le haremos una propuesta para satisfacerlo",
    //   image: "/static/img/services/hosting-solutionops.png"
    // },
    {
      name: "DESARROLLO SOFTWARE",
      content: "Desarrollamos sus ideas y potenciamos su negocio brindándole toda la asesoría necesaria en cuanto a desarrollo e implementación de software.",
      image: "/static/img/services/desarrollo-web-solutionops.webp"
    },
    {
      name: "OUTSOURCING TECONOLOGICO",
      content: "Facilitamos la gestión de talento TI de su empresa y ayudamos con la simplificación de todos los procesos administrativos, permitiendo que se puedan concentrar directamente en las necesidades de su negocio.",
      image: "/static/img/services/devops-solutionops.webp"
    },
    {
      name: "RECLUTAMIENTO TI",
      content: "Nuestro servicio de reclutamiento nos permite facilitar el proceso de selección de su empresa, entendiendo las necesidades del rol y buscando los perfiles que mejor se adapten.",
      image: "/static/img/services/e-commerce-solutionops.webp"
    }
  ];

  return (
    <div className="container segment-spacing center-text-segment services-segment" ref={serviceRef}>
      <div className="row">
        <div className="col-md-12">
          <h2 className="dark-header-text mb50">NUESTROS SERVICIOS</h2>
        </div>

        {
          services.map((service, index)=>(
            <div
              key={index}
              className="col-lg-4 service-box">

              <div className="align-icon">
                <div className="aio-icon-img  uavc-circle">
                  <img
                    loading="lazy"
                    width="80"
                    height="80"
                    className="img-icon"
                    alt={service.name}
                    src={service.image}/>
                </div>
                <h3 className="dark-header-text service-title">{service.name}</h3>
                <p className="p-text-service-box">{service.content}</p>
                <div className="col-md-12">
                  <button
                    type="submit"
                    className="btn btn-custom-submit-contact"
                    onClick={()=>goTo('/contacto-web')}>{'Más Información'}</button>
                </div>
              </div>

            </div>
          ))
        }
      </div>
    </div>
  )
}

export default Services;
