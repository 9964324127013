import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const ProgrammingLangs = () => {

  const clients = [
    {
      image: "/static/img/langs/node-js.png"
    },
    {
      image: "/static/img/langs/gcp.png"
    },
    {
      image: "/static/img/langs/java-logo.png"
    },
    {
      image: "/static/img/langs/aws-logo.png"
    },
    {
      image: "/static/img/langs/PHP-logo.png"
    },
    {
      image: "/static/img/langs/android-logo.png"
    },
    {
      image: "/static/img/langs/python-logo.png"
    },
    {
      image: "/static/img/langs/angular.png"
    },
    {
      image: "/static/img/langs/vuejs.png"
    },
    {
      image: "/static/img/langs/react.png"
    }

  ];

  const options = {
          loop: true,
          margin: 20,
          dots: false,
          autoplay: true,
          autoplayTimeout: 1500,
          responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 4,
            },
        },
  }


  return (
    <div className="container segment-spacing-300 center-text-segment services-segment">
      <div className="row">
        {/* <div className="col-md-12">
          <h2 className="dark-header-text-1 mb50">Trabajamos con Diversas Tecnologías</h2>
        </div> */}

        <div className="col-md-12">
          <OwlCarousel
            className='owl-theme'
            {...options}>
            {
              clients.map((image, index) => (
                <div className='item center-text-segment' key={index}>
                    <img
                      loading="lazy"
                      width="auto"
                      height="auto"
                      className="img-owl"
                      alt={index}
                      src={image.image}/>
                </div>
              ))
            }
          </OwlCarousel>
        </div>
      </div>
    </div>
  )
}

export default ProgrammingLangs;
