import { useRef, useEffect } from "react";

import Header from "../../components/Header.js";
import Footer from "../../components/Footer.js";

import { InlineShareButtons } from "sharethis-reactjs";

const CorsNodeBlogPage = () => {
  useEffect(() => {
    document.getElementById("canonical").href =
      "https://solutionops.cl/categorias/desarrollo-de-software/cors-node";
    document.getElementById("og_site_title").content =
      "Controlando el Acceso a Recursos Externos: Una Guía Completa sobre CORS en Node.js";
    document.getElementById("site_keywords").content =
      "Asegurando tu Aplicación Web con Cross-Origin Resource Sharing y el Paquete CORS en Node.js";
    document.getElementById("og_site_description").content =
      "Asegurando tu Aplicación Web con Cross-Origin Resource Sharing y el Paquete CORS en Node.js";
    document.getElementById("site_description").content =
      "Asegurando tu Aplicación Web con Cross-Origin Resource Sharing y el Paquete CORS en Node.js";
  }, []);

  const scrollToRef = (ref) =>
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: "smooth",
    });
  const contactRef = useRef(null);

  const openWhatsapp = () => {
    let url =
      "https://api.whatsapp.com/send/?phone=56991981647&text&app_absent=0";
    window.open(url);
  };

  const goTo = (page) => {
    window.location.assign(page);
    // let url = page;
    // window.href(url);
  };

  const openCall = () => {
    let url = "tel:0056991981647";
    window.open(url);
  };

  return (
    <div className="App">
      <Header
        goTo={goTo}
        scrollToRef={scrollToRef}
        openWhatsapp={openWhatsapp}
        openCall={openCall}
      />

      <div className="container-fluid bg-blog  bg-grey">
        <div className="container main-div-title bg-white">
          <div className="row">
            <div className="col-md-12">
              <h1 className="blog-title">
                Controlando el Acceso a Recursos Externos: Una Guía Completa
                sobre CORS en Node.js
              </h1>
              <h2 className="blog-subtitle">
                Asegurando tu Aplicación Web con Cross-Origin Resource Sharing y
                el Paquete CORS en Node.js
              </h2>
              <br />
              <br />
            </div>
            <div className="col-lg-8">
              <p className="blog-text">
                Las CORS (Cross-Origin Resource Sharing) son una política de
                seguridad implementada en los navegadores web para controlar las
                solicitudes de recursos entre diferentes dominios. Permiten que
                un servidor especifique qué dominios externos tienen permiso
                para acceder a sus recursos a través de JavaScript.
              </p>
              <h3 className="h3-title">Funcionamiento de los Triggers</h3>
              <p className="blog-text">
                Para habilitar CORS en Node.js, puedes usar el paquete cors.
                Primero, instala el paquete ejecutando npm install cors. Luego,
                en tu aplicación Node.js, puedes agregar el middleware de CORS
                de la siguiente manera:
              </p>
              <pre>
                <code>
                  {`
const express = require('express');
const cors = require('cors');

const app = express();
app.use(cors());

// Rutas y lógica de tu aplicación

app.listen(3000, () => {
 	console.log('Servidor iniciado en el puerto 3000');
});
                                    `}
                </code>
              </pre>
              <p className="blog-text">
                Esto permitirá solicitudes de cualquier origen. Si deseas
                restringir el acceso solo a dominios específicos, puedes
                proporcionar opciones adicionales al middleware de CORS:
              </p>
              <pre>
                <code>
                  {`
app.use(cors({
  origin: 'https://example.com' // Cambia esto al dominio permitido
}));
                                    `}
                </code>
              </pre>
              <p className="blog-text">
                De esta manera, solo se permitirán solicitudes desde el dominio
                especificado. Puedes proporcionar una matriz de dominios si
                deseas permitir múltiples orígenes. Recuerda que CORS es una
                medida de seguridad en el navegador y no debe ser la única capa
                de seguridad en tu aplicación. También debes implementar otras
                medidas de seguridad en el lado del servidor.
              </p>

              <p className="blog-text">
                {"<solutionops-team> Autor: Ramon Duran </solutionops-team>"}
              </p>
            </div>
            <div className="col-lg-4">
              <InlineShareButtons
                config={{
                  alignment: "center",
                  color: "social",
                  enabled: true,
                  font_size: 16,
                  networks: [
                    "whatsapp",
                    "linkedin",
                    "messenger",
                    "facebook",
                    "twitter",
                    "email",
                  ],
                  padding: 12,
                  radius: 0,
                  show_total: false,
                  size: 40,
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <Footer openWhatsapp={openWhatsapp} />
    </div>
  );
};

export default CorsNodeBlogPage;
