import axios from 'axios';


let header = {
    'Content-Type': 'application/json'
};

const sendMessage = (obj) => {

  let url = `https://api.telegram.org/${process.env.REACT_APP_TELEGRAM_BOT_ID}/sendMessage`;


  return axios.post(url, obj, {
            headers: header
         });
};

const exportedObject = {
     sendMessage
};

export default exportedObject;
