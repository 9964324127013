import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const OurClients = () => {

  const clients = [
    {
      image: "/static/img/clients/dimasoft-min.png",
      url: "https://dimasoft.cl"
    },
    {
      image: "/static/img/clients/jetsmart-min.png",
      url: "https://jetsmart.com"
    },
    {
      image: "/static/img/clients/ovalgroup-min.png",
      url: "https://ovalgroup.cl"
    },
    {
      image: "/static/img/clients/itntsolutions-min.png",
      url: "http://itntsolutions.net"
    },
    {
      image: "/static/img/clients/max-delivery-min.png",
      url: "https://max.delivery"
    },
    {
      image: "/static/img/clients/comerydivertirse-min.png",
      url: "https://comerydivertirse.com"
    },
    {
      image: "/static/img/clients/abogadosoluciones-min.png",
      url: "https://abogadosoluciones.cl/"
    },
    {
      image: "/static/img/clients/saborwithaloha-min.png",
      url: "http://saborwithaloha.com/"
    },
    {
      image: "/static/img/clients/petuniamoda-min.png",
      url: "https://petuniamoda.shop/"
    }
  ];

  const options = {
          loop: true,
          margin: 20,
          dots: false,
          autoplay: true,
          autoplayTimeout: 1500,
          responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 4,
            },
        },
  }


  return (
    <div className="container segment-spacing-300 center-text-segment services-segment">
      <div className="row">
        <div className="col-md-12">
          <h2 className="dark-header-text mb50">ALGUNOS DE NUESTROS CLIENTES</h2>
        </div>

        <div className="col-md-12">
          <OwlCarousel
            className='owl-theme'
            {...options}>
            {
              clients.map((image, index) => (
                <div className='item center-text-segment' key={index}>
                    <img
                      loading="lazy"
                      width="auto"
                      height="auto"
                      className="img-owl"
                      alt={index}
                      src={image.image}/>
                </div>
              ))
            }
          </OwlCarousel>
        </div>
      </div>
    </div>
  )
}

export default OurClients;
