import { useRef, useEffect } from 'react';

import Header from '../../components/Header.js';
import Footer from '../../components/Footer.js';

import {InlineShareButtons} from 'sharethis-reactjs';

const ValidacionesVuestifyBlogPage = () => {

  useEffect(() => {
    document.getElementById("canonical").href = "https://solutionops.cl/categorias/desarrollo-de-software/validaciones-con-vuetify";
    document.getElementById("og_site_title").content = "Validaciones con Vuetify";
    document.getElementById("site_keywords").content = "Validaciones con Vuetify, Vue Js, Node Js";
    document.getElementById("og_site_description").content = "Validaciones con Vuetify, Vue Js, Node Js";
    document.getElementById("site_description").content = "Validaciones con Vuetify, Vue Js, Node Js";
  }, []);

  const scrollToRef = (ref) =>  window.scrollTo({
    top: ref.current.offsetTop,
    behavior: 'smooth',
  });
  const contactRef = useRef(null);
    
  const openWhatsapp = () => {
    let url = "https://api.whatsapp.com/send/?phone=56991981647&text&app_absent=0";
    window.open(url);
  }

  const goTo = (page) => {
    window.location.assign(page)
    // let url = page;
    // window.href(url);
  }

  const openCall = () => {
    let url = "tel:0056991981647";
    window.open(url);
  }

  return (
    <div className="App">
        <Header
            goTo={goTo}
            scrollToRef={scrollToRef}
            openWhatsapp={openWhatsapp}
            openCall={openCall}
        />

        <div className="container-fluid bg-blog  bg-grey">
            <div className="container main-div-title bg-white">
                <div className="row">
                    <div className='col-md-12'>
                        <h1 className="blog-title">Validaciones con Vuetify</h1>
                        <h2 className="blog-subtitle">Valida tus formularios en VUE3 de manera correcta, fácil y rápida con Vuetify</h2>
                        <br/>
                    </div>
                    <div className='col-lg-8'>
         

                            <p className='blog-text'>La validación de formularios es una parte crucial en el desarrollo de aplicaciones web, ya que garantiza que los datos ingresados por los usuarios sean correctos y cumplan con los requisitos establecidos. Vuetify, una biblioteca de componentes para Vue.js, ofrece herramientas poderosas para facilitar la validación de formularios en tus proyectos. En este artículo, exploraremos cómo utilizar Vuetify y Vue 3 para validar formularios de manera sencilla y efectiva.</p>
                            <h3 className='h3-title'>1. Ejecuta el siguiente comando para instalar Vuetify “npm install vuetify@next”</h3>
                            <h3 className='h3-title'>2. importa Vuetify en tu archivo main.js (o en el archivo principal de tu aplicación)</h3>

                            <img
                                loading="lazy"
                                width="auto"
                                height="auto"
                                className="img-blog"
                                alt="async-await-solutionops-desarrollo-de-software-javascript"
                                src={"/static/img/blog/vuetify-1-solutionops.png"}/>


                            <p className='blog-text'>Asegúrate de importar los estilos de Vuetify en tu archivo App.vue (o en el componente raíz de tu aplicación). Puedes hacerlo añadiendo la siguiente línea al principio del archivo</p>

                            <img
                                loading="lazy"
                                width="auto"
                                height="auto"
                                className="img-blog"
                                alt="async-await-solutionops-desarrollo-de-software-javascript"
                                src={"/static/img/blog/vuetify-2-solutionops.png"}/>

                            <p className='blog-text'>Ahora, ya estás listo para utilizar los componentes de Vuetify en tu proyecto. A continuación, mostraremos un ejemplo básico de cómo se vería el código del formulario de contacto:</p>

                            <img
                                loading="lazy"
                                width="auto"
                                height="auto"
                                className="img-blog"
                                alt="async-await-solutionops-desarrollo-de-software-javascript"
                                src={"/static/img/blog/vuetify-3-solutionops.png"}/>

                            <p className='blog-text'>En este ejemplo, hemos utilizado los componentes de Vuetify como v-form, v-text-field y v-textarea para construir el formulario de contacto. Cada campo tiene una propiedad v-model que está enlazada a los datos del componente (por ejemplo, name, email y message).</p>
                            <p className='blog-text'>Hemos definido reglas de validación para cada campo utilizando las propiedades: rules. Las reglas de validación son funciones que devuelven un mensaje de error si la validación no se cumple. Por ejemplo, v => !!v || 'El nombre es requerido' verifica que el campo del nombre no esté vacío.</p>

                            <img
                                loading="lazy"
                                width="auto"
                                height="auto"
                                className="img-blog"
                                alt="async-await-solutionops-desarrollo-de-software-javascript"
                                src={"/static/img/blog/vuetify-4-solutionops.png"}/>


                            <p className='blog-text'>En el método submitForm, utilizamos this.$refs.form.validate() para validar todos los campos del formulario. Si la validación es exitosa, se puede realizar la acción de envío del formulario (en este caso, simplemente mostramos un mensaje en la consola). De lo contrario, se mostrará un mensaje de error.</p>

                            <p className='blog-text'>{'<solutionops-team> Autor: Ramón Durán </solutionops-team>'}</p>

                    </div>
                    <div className='col-lg-4'>
                        <InlineShareButtons
                            config={{
                                alignment: 'center',
                                color: 'social',
                                enabled: true,
                                font_size: 16,
                                networks: [
                                'whatsapp',
                                'linkedin',
                                'messenger',
                                'facebook',
                                'twitter',
                                'email'
                                ],
                                padding: 12,
                                radius: 0,
                                show_total: false,
                                size: 40
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>

        <Footer
            openWhatsapp={openWhatsapp}
        />
    </div>
  );
}

export default ValidacionesVuestifyBlogPage;
