import Form from "./Form";

const Banner = ({ scrollToRef, serviceRef, contactRef, testService }) => {

  const imageBackgroundWeb = "/static/img/solutionops-chile-banner-min.jpeg";
  const imageBackgroundMovil = "/static/img/solutionops-chile-banner-mobile-min.jpeg";

  return (
    <div className="container-fluid px-0 ">
      <div
        className="row no-gutters img-bgr"
      >
        <div className="col-12 cont-img img-fluid cms-banner">
          <div className="main-banner-div">
            <h1 className="font-slider-desktop ">
              CONSULTORIA DE TECNOLOGIA
              <br /> Y SOFTWARE
            </h1>
            <p className="font-slider-text">
              TE ASESORAMOS Y APOYAMOS CON EL{" "}
              <strong>DESARROLLO E IMPLEMENTACIÓN</strong>
              <br />
              DE TUS IDEAS
            </p>
          <Form />
          </div>
        </div>

          {/* <div className="text-left-mobile cont-img cms-banner d-md-none d-block ">
              <h1 className="font-slider-mobile">
                CONSULTORIA DE TECNOLOGIA
                <br /> Y SOFTWARE
              </h1>
              <p className="font-slider-text-mobile">
                TE ASESORAMOS Y APOYAMOS CON EL <br />
                <strong>DESARROLLO E IMPLEMENTACIÓN</strong>
                <br />
                DE TUS IDEAS
              </p>
          </div> */}
      </div>
    </div>
  );
};

export default Banner;
