import { useRef, useEffect } from "react";

import Header from "../../components/Header.js";
import Footer from "../../components/Footer.js";

import { InlineShareButtons } from "sharethis-reactjs";

const FechasPhpBlogPage = () => {
  useEffect(() => {
    document.getElementById("canonical").href =
      "https://solutionops.cl/categorias/desarrollo-de-software/fechas-php";
    document.getElementById("og_site_title").content =
      "Manejo de fechas en PHP";
    document.getElementById("site_keywords").content =
      "Domina el Manejo de Fechas en PHP: Trucos y Consejos para un Código Preciso y Eficiente";
    document.getElementById("og_site_description").content =
      "Domina el Manejo de Fechas en PHP: Trucos y Consejos para un Código Preciso y Eficiente";
    document.getElementById("site_description").content =
      "Domina el Manejo de Fechas en PHP: Trucos y Consejos para un Código Preciso y Eficiente";
  }, []);

  const scrollToRef = (ref) =>
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: "smooth",
    });
  const contactRef = useRef(null);

  const openWhatsapp = () => {
    let url =
      "https://api.whatsapp.com/send/?phone=56991981647&text&app_absent=0";
    window.open(url);
  };

  const goTo = (page) => {
    window.location.assign(page);
    // let url = page;
    // window.href(url);
  };

  const openCall = () => {
    let url = "tel:0056991981647";
    window.open(url);
  };

  return (
    <div className="App">
      <Header
        goTo={goTo}
        scrollToRef={scrollToRef}
        openWhatsapp={openWhatsapp}
        openCall={openCall}
      />

      <div className="container-fluid bg-blog  bg-grey">
        <div className="container main-div-title bg-white">
          <div className="row">
            <div className="col-md-12">
              <h1 className="blog-title">Manejo de fechas en PHP</h1>
              <h2 className="blog-subtitle">
                Domina el Manejo de Fechas en PHP: Trucos y Consejos para un
                Código Preciso y Eficiente
              </h2>
              <br />
              <br />
            </div>
            <div className="col-lg-8">
              <p className="blog-text">
                El manejo de fechas es una tarea común en el desarrollo web, ya
                sea para mostrar la fecha actual, realizar cálculos temporales o
                formatear fechas en diferentes formatos. PHP, uno de los
                lenguajes de programación más populares para la web, proporciona
                una serie de funciones y clases incorporadas que facilitan el
                trabajo con fechas. En este artículo, exploraremos las diversas
                técnicas y funciones disponibles en PHP para el manejo de
                fechas.
              </p>
              <p className="blog-text">
                Obtener la fecha actual: En PHP, puedes obtener la fecha actual
                utilizando la función date(). Aquí tienes un ejemplo sencillo:
              </p>
              <pre>
                <code>
                  {`
$currentDate = date("Y-m-d"); 
echo "La fecha actual es: " . $currentDate;

                                    `}
                </code>
              </pre>
              <p className="blog-text">
                Esto imprimirá la fecha actual en el formato "año-mes-día", como
                por ejemplo: "2023-06-30".
              </p>
              <p className="blog-text">
                Manipulación de fechas: PHP ofrece varias funciones útiles para
                manipular fechas, como strtotime() y date_modify(). Algunos
                ejemplos de cómo utilizar estas funciones:
              </p>
              <pre>
                <code>
                  {`
// Sumar 1 día a una fecha 
$date = "2023-06-30"; 
$nextDay = date("Y-m-d", strtotime($date . " +1 day")); 
echo "Mañana será: " . $nextDay;

// Restar 1 semana a una fecha 
$date = "2023-06-30"; 
$previousWeek = date("Y-m-d", strtotime($date . " -1 week")); 
echo "Hace una semana fue: " . $previousWeek; 

// Modificar una fecha específica 
$date = date_create("2023-06-30"); 
date_modify($date, "+2 months"); 
echo "Dentro de 2 meses será: " . date_format($date, "Y-m-d"); 
                                    `}
                </code>
              </pre>
              <p className="blog-text">
                Formateo de fechas: En PHP, puedes formatear fechas utilizando
                la función date() y los caracteres especiales que representan
                diferentes partes de una fecha. Aquí tienes algunos ejemplos:
              </p>
              <pre>
                <code>
                  {`
$timestamp = time(); 
// Obtener la fecha actual 
echo date("d/m/Y", $timestamp); 
Esto imprimirá la fecha actual en el formato "día-mes-año", como por ejemplo: "30/06/2023".

echo date("F j, Y", $timestamp); 
Esto imprimirá la fecha actual en el formato "mes-día-año", como por ejemplo: " June 30, 2023".

 echo date("H:i:s", $timestamp); 
                                    `}
                </code>
              </pre>
              <p className="blog-text">
                Esto imprimirá la hora actual en el formato
                "hora-minuto-segundo", como por ejemplo: "12:34:56 ".
              </p>
              <p className="blog-text">
                Conversión de formatos de fechas: Si necesitas convertir una
                fecha de un formato a otro, puedes utilizar las funciones
                strtotime() y date() en combinación:
              </p>
              <pre>
                <code>
                  {`
$dateString = "30-06-2023"; 
$timestamp = strtotime($dateString); 
$newFormat = date("Y-m-d", $timestamp); 
echo "Fecha en formato 'año-mes-día': " . $newFormat; 
                                    `}
                </code>
              </pre>
              <p className="blog-text">
                {"<solutionops-team> Autor: Jojan Cardenas </solutionops-team>"}
              </p>
            </div>
            <div className="col-lg-4">
              <InlineShareButtons
                config={{
                  alignment: "center",
                  color: "social",
                  enabled: true,
                  font_size: 16,
                  networks: [
                    "whatsapp",
                    "linkedin",
                    "messenger",
                    "facebook",
                    "twitter",
                    "email",
                  ],
                  padding: 12,
                  radius: 0,
                  show_total: false,
                  size: 40,
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <Footer openWhatsapp={openWhatsapp} />
    </div>
  );
};

export default FechasPhpBlogPage;
