import Form from "../components/Form";
import ProgrammingLangs from '../segments/ProgrammingLangs.js';
import { useRef } from 'react';

const Blog = ({
}) => {

  const scrollToRef = (ref) =>  window.scrollTo({
    top: ref.current.offsetTop,
    behavior: 'smooth',
  });
  // const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
  const contactRef = useRef(null);

  return (
    <div>
      <div className="img-bgr-dev">
        <div className="container main-div-title">
          <h1 className="h1-main-blog-title-wt">Desarrollo de Software y Aplicaciones</h1>
          <h2 className="h2-main-blog-title-wt">Soluciones para su Empresa</h2>
          <p className="p-main-blog-title-wt">{'(e) => { console.log("Le apoyamos y soportamos sus procesos operacionales") }'}</p>
          <button
              type="submit"
              className="btn btn-custom-submit-dev mb-120"
              onClick={()=>scrollToRef(contactRef)}>{'Más Información'}</button>
        </div>
      </div>
      <div className="container-fluid bg-blog nopaddingsides">
        <div className="container mt30p ">
          <div className="row">
            <div className="col-md-12">
              <div class="card card-dev">
                <div class="container text-center">
                  <h4><b>¿Qué es el <strong>Desarrollo de Software</strong>?</b></h4>
                  <br/>
                  <p className="text-cl-000">El desarrollo de software es el proceso de diseñar, crear y mantener <strong className="color-orange">aplicaciones de software personalizadas para satisfacer las necesidades específicas de una empresa o cliente.</strong> Esto incluye la programación de aplicaciones de software, la creación de bases de datos, la integración de sistemas y la personalización de soluciones de software existentes.</p>
                  <br/>
                  <p className="text-cl-000">Nos enorgullece ofrecer <strong className="color-orange">soluciones de desarrollo de software de alta calidad que son escalables, eficientes y rentables.</strong> Trabajamos en estrecha colaboración con nuestros clientes para comprender sus requisitos únicos y ofrecer soluciones que satisfagan sus necesidades comerciales a largo plazo.</p>
                </div>
              </div>

              {/* <h1 className="h1-main-blog-title">¿Qué es el <strong>Desarrollo de Software?</strong></h1>
              <p className="blog-txt">El desarrollo de software es el proceso de diseñar, crear y mantener aplicaciones de software personalizadas para satisfacer las necesidades específicas de una empresa o cliente. Esto incluye la programación de aplicaciones de software, la creación de bases de datos, la integración de sistemas y la personalización de soluciones de software existentes.</p> */}
              {/* <p className="blog-txt">Nos enorgullece ofrecer soluciones de desarrollo de software de alta calidad que son escalables, eficientes y rentables. Trabajamos en estrecha colaboración con nuestros clientes para comprender sus requisitos únicos y ofrecer soluciones que satisfagan sus necesidades comerciales a largo plazo.</p>
              <p className="blog-txt">Ofrecemos una amplia gama de servicios de desarrollo de software, que incluyen el desarrollo de aplicaciones de software personalizadas, la integración de sistemas, la migración de datos, la creación de bases de datos y la personalización de soluciones de software existentes. Nos aseguramos de que todos nuestros proyectos se entreguen en tiempo y forma y cumplen con los más altos estándares de calidad.</p> */}
            </div>
          </div>
        </div>
        <div className="segment-spacing-100" ref={contactRef}></div>
        <ProgrammingLangs/>
        <div className="segment-spacing-100" ref={contactRef}></div>
        <div className="mt30p bg-grey nopaddingsides">
          <div className="container ">
            <div className="row">
              <div className="col-md-6 ">
                <p className="title-contact-in-dev mg-top-in-text">¡Contacte un Ejecutivo!</p>
                <p className="blog-txt">En <strong>SolutionOps SpA</strong>, brindamos servicios de desarrollo de software personalizados para empresas de cualquier tamaño y sector en Chile.</p>

              </div>
              <div className="col-md-6 text-center">
                  <Form />    
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Blog;
