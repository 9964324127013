import { useRef, useEffect } from "react";

import Header from "../../components/Header.js";
import Footer from "../../components/Footer.js";

import { InlineShareButtons } from "sharethis-reactjs";

const MetodoHungaroBlogPage = () => {
  useEffect(() => {
    document.getElementById("canonical").href =
      "https://solutionops.cl/categorias/desarrollo-de-software/metodo-hungaro";
    document.getElementById("og_site_title").content = "El método húngaro";
    document.getElementById("site_keywords").content =
      "algoritmo de Munkres para resolver problemas de asignación";
    document.getElementById("og_site_description").content =
      "algoritmo de Munkres para resolver problemas de asignación";
    document.getElementById("site_description").content =
      "algoritmo de Munkres para resolver problemas de asignación";
  }, []);

  const scrollToRef = (ref) =>
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: "smooth",
    });
  const contactRef = useRef(null);

  const openWhatsapp = () => {
    let url =
      "https://api.whatsapp.com/send/?phone=56991981647&text&app_absent=0";
    window.open(url);
  };

  const goTo = (page) => {
    window.location.assign(page);
    // let url = page;
    // window.href(url);
  };

  const openCall = () => {
    let url = "tel:0056991981647";
    window.open(url);
  };

  return (
    <div className="App">
      <Header
        goTo={goTo}
        scrollToRef={scrollToRef}
        openWhatsapp={openWhatsapp}
        openCall={openCall}
      />

      <div className="container-fluid bg-blog  bg-grey">
        <div className="container main-div-title bg-white">
          <div className="row">
            <div className="col-md-12">
              <h1 className="blog-title">El método húngaro</h1>
              <h2 className="blog-subtitle">
                Algoritmo de Munkres para resolver problemas de asignación
              </h2>
              <br />
              <br />
            </div>
            <div className="col-lg-8">
              <p className="blog-text">
                El método húngaro, también conocido como el algoritmo de
                Munkres, es un algoritmo eficiente y ampliamente utilizado para
                resolver problemas de asignación. Este algoritmo encuentra la
                asignación óptima en términos de costo o beneficio, minimizando
                o maximizando una función objetivo. En este artículo,
                exploraremos en detalle el método húngaro y cómo puede aplicarse
                a una variedad de situaciones de asignación.
              </p>
              <h3 className="h3-title">
                El método húngaro y su funcionamiento:
              </h3>
              <p className="blog-text">
                El algoritmo de Munkres se basa en la teoría de grafos y emplea
                una combinación de técnicas de búsqueda exhaustiva y
                optimización para encontrar la solución óptima. El enfoque
                principal del método húngaro es encontrar una matriz de costos
                reducida que refleje las condiciones de asignación.
              </p>
              <p className="blog-text">
                A continuación, se presenta una descripción general del proceso
                del algoritmo:
              </p>
              <ol className="blog-text">
                <li>
                  <h3 className="h3-title">Inicialización</h3>
                  <p className="blog-text">
                    Dada una matriz de costos o beneficios, se realizan una
                    serie de ajustes para obtener una matriz reducida inicial.
                  </p>
                  <p className="blog-text">
                    Se asigna el valor de cero a un número mínimo de líneas
                    horizontales o verticales que cubren todas las celdas con
                    valor cero.
                  </p>
                  <p className="blog-text">
                    Se marcan las celdas que quedan sin cubrir.
                  </p>
                </li>
                <li>
                  <h3 className="h3-title">Asignación de celdas:</h3>
                  <p className="blog-text">
                    Se selecciona una celda no marcada y se realiza una
                    asignación.
                  </p>
                  <p className="blog-text">
                    Si hay una única celda no marcada en su fila o columna, se
                    realiza la asignación de manera directa.
                  </p>
                  <p className="blog-text">
                    Si hay varias celdas no marcadas, se elige una de ellas y se
                    marca. Luego, se elimina la marca de cualquier otra celda
                    asignada previamente en la misma columna o fila.
                  </p>
                </li>
                <li>
                  <h3 className="h3-title">Test de optimalidad:</h3>
                  <p className="blog-text">
                    Se verifica si la solución actual es óptima. Si es así, se
                    finaliza el algoritmo.
                  </p>
                  <p className="blog-text">
                    Si no es óptima, se procede al paso 4.
                  </p>
                </li>
                <li>
                  <h3 className="h3-title">Mejora de la solución:</h3>
                  <p className="blog-text">
                    Se realizan una serie de ajustes para mejorar la solución
                    actual.
                  </p>
                  <p className="blog-text">
                    Se identifica una ruta de mejora y se modifica la asignación
                    en esa ruta.
                  </p>
                  <p className="blog-text">
                    Luego, se regresa al paso 2 y se repite el proceso.
                  </p>
                </li>
              </ol>
              <h3 className="h3-title">Aplicaciones del método húngaro:</h3>
              <p className="blog-text">
                El método húngaro tiene una amplia gama de aplicaciones en
                diversas áreas, como la asignación de recursos, la programación
                lineal, la optimización de tareas y la planificación. Algunos
                ejemplos de situaciones en las que se puede aplicar el algoritmo
                de Munkres son:
              </p>
              <ul className="block-text">
                <li>
                  <h3 className="h3-title">Asignación de tareas:</h3>
                  <p className="blog-text">
                    Se puede utilizar para asignar trabajadores a diferentes
                    tareas, maximizando la eficiencia o minimizando los costos.
                  </p>
                </li>
                <li>
                  <h3 className="h3-title">Problemas de transporte:</h3>
                  <p className="blog-text">
                    Ayuda a encontrar la asignación óptima de recursos en
                    problemas de transporte, como la distribución de mercancías
                    o la programación de rutas de entrega.
                  </p>
                </li>
                <li>
                  <h3 className="h3-title">Asignación de personal:</h3>
                  <p className="blog-text">
                    En entornos como hospitales o centros de llamadas, el método
                    húngaro puede ayudar a asignar personal de manera óptima,
                    considerando diferentes criterios, como habilidades,
                    disponibilidad y carga de trabajo.
                  </p>
                </li>
              </ul>
              <p className="blog-text">
                {"<solutionops-team> Autor: Fabián Pérez </solutionops-team>"}
              </p>
            </div>
            <div className="col-lg-4">
              <InlineShareButtons
                config={{
                  alignment: "center",
                  color: "social",
                  enabled: true,
                  font_size: 16,
                  networks: [
                    "whatsapp",
                    "linkedin",
                    "messenger",
                    "facebook",
                    "twitter",
                    "email",
                  ],
                  padding: 12,
                  radius: 0,
                  show_total: false,
                  size: 40,
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <Footer openWhatsapp={openWhatsapp} />
    </div>
  );
};

export default MetodoHungaroBlogPage;
