import { useRef, useEffect } from "react";

import Header from "../../components/Header.js";
import Footer from "../../components/Footer.js";

import { InlineShareButtons } from "sharethis-reactjs";

const QueryInjectionBlogPage = () => {
  useEffect(() => {
    document.getElementById("canonical").href =
      "https://solutionops.cl/categorias/desarrollo-de-software/query-injection";
    document.getElementById("og_site_title").content =
      "Inyección de SQL (SQL Injection)";
    document.getElementById("site_keywords").content =
      "Inyección de SQL (SQL Injection)";
    document.getElementById("og_site_description").content =
      "Inyección de SQL (SQL Injection)";
    document.getElementById("site_description").content =
      "Inyección de SQL (SQL Injection)";
  }, []);

  const scrollToRef = (ref) =>
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: "smooth",
    });
  const contactRef = useRef(null);

  const openWhatsapp = () => {
    let url =
      "https://api.whatsapp.com/send/?phone=56991981647&text&app_absent=0";
    window.open(url);
  };

  const goTo = (page) => {
    window.location.assign(page);
    // let url = page;
    // window.href(url);
  };

  const openCall = () => {
    let url = "tel:0056991981647";
    window.open(url);
  };

  return (
    <div className="App">
      <Header
        goTo={goTo}
        scrollToRef={scrollToRef}
        openWhatsapp={openWhatsapp}
        openCall={openCall}
      />

      <div className="container-fluid bg-blog  bg-grey">
        <div className="container main-div-title bg-white">
          <div className="row">
            <div className="col-md-12">
              <h1 className="blog-title">Inyección de SQL (SQL Injection)</h1>
              <h2 className="blog-subtitle">
                SQL Injection: Una vulnerabilidad crítica que amenaza la
                seguridad de tus aplicaciones web
              </h2>
              <br />
              <br />
            </div>
            <div className="col-lg-8">
              <p className="blog-text">
                La inyección de SQL (SQL injection, en inglés) es una técnica de
                ataque informático que consiste en aprovechar una vulnerabilidad
                en una aplicación o sistema web que interactúa con una base de
                datos mediante consultas SQL. La vulnerabilidad permite que un
                atacante pueda insertar sentencias SQL maliciosas en los campos
                de entrada de la aplicación (comúnmente formularios), engañando
                al sistema para que ejecute estos comandos de forma no deseada.
              </p>
              <p className="blog-text">
                El objetivo principal de un ataque de inyección de SQL es
                obtener acceso no autorizado a la base de datos o extraer
                información confidencial almacenada en ella. Los atacantes
                pueden manipular las consultas SQL existentes o agregar nuevas
                consultas para llevar a cabo acciones maliciosas, como la
                modificación o eliminación de datos, la obtención de información
                confidencial o incluso el control total del sistema.
              </p>
              <p className="blog-text">
                A modo de ejemplo, supongamos que tienes un formulario de inicio
                de sesión en una aplicación web que utiliza consultas SQL para
                verificar las credenciales del usuario en una base de datos. El
                código subyacente podría ser similar a este:
              </p>
              <pre>
                <code>
                  {`
$username = $_POST['username'];
$password = $_POST['password'];

$query = "SELECT * FROM usuarios WHERE nombre_usuario='$username' AND contraseña='$password'";
                                    `}
                </code>
              </pre>
              <p className="blog-text">
                En este caso, el código toma los valores de usuario y contraseña
                ingresados por el usuario a través del formulario y los
                concatena directamente en la consulta SQL. Sin embargo, esto es
                peligroso porque permite la posibilidad de una inyección de SQL.
              </p>
              <p className="blog-text">
                Un atacante podría aprovechar esta vulnerabilidad ingresando una
                entrada maliciosa en el campo del nombre de usuario. Por
                ejemplo, si el atacante ingresa ' OR '1'='1' # como nombre de
                usuario y no ingresa ninguna contraseña, la consulta SQL
                resultante sería:
              </p>
              <pre>
                <code>
                  {`
SELECT * FROM usuarios WHERE nombre_usuario='' OR '1'='1' #' AND contraseña=''";
                                    `}
                </code>
              </pre>
              <p className="blog-text">
                La validación OR '1'='1' siempre dará como resultado TRUE, por
                lo que se realizará el inicio de sesión, además, el carácter #
                comentará el resto de la consulta (en el caso de mysql), para
                que esto no cause inconvenientes al momento de iniciar sesión.
              </p>
              <p className="blog-text">
                Para evitar este tipo de vulnerabilidades, es fundamental
                utilizar técnicas de mitigación, como las consultas
                parametrizadas. Esta técnica permite separar los datos de la
                consulta SQL, evitando así la posibilidad de inyección de
                código.
              </p>
              <p className="blog-text">
                Por ejemplo, utilizando consultas parametrizadas, el código se
                vería de la siguiente manera:
              </p>
              <pre>
                <code>
                  {`
$username = $_POST['username'];
$password = $_POST['password'];

$query = "SELECT * FROM usuarios WHERE nombre_usuario=? AND contraseña=?";
$stmt = $mysqli->prepare($query);
$stmt->bind_param("ss", $username, $password);
$stmt->execute();

                                    `}
                </code>
              </pre>
              <p className="blog-text">
                En este caso, en lugar de concatenar los valores directamente en
                la consulta SQL, utilizamos marcadores de posición (?) y luego
                vinculamos los valores reales a través del método bind_param().
                Esto asegura que los datos ingresados por el usuario se traten
                como valores, y no como parte de la consulta en sí.
              </p>
              <p className="blog-text">
                Al utilizar consultas parametrizadas se reduce drásticamente el
                riesgo de inyección de SQL, ya que la base de datos trata los
                valores proporcionados como datos, y no como parte de la
                consulta. Esto ayuda a mantener la seguridad de la aplicación y
                proteger los datos sensibles de los usuarios.
              </p>
              <p className="blog-text">
                Sumado a lo anterior, y aunque es importante estar consciente de
                este tipo de vulnerabilidades, gracias a la alta presencia de
                librerías y frameworks en los lenguajes de programación más
                utilizados actualmente, cada vez es más fácil evitar esta
                vulnerabilidad.
              </p>

              <p className="blog-text">
                {
                  "<solutionops-team> Autor: Jonathan Urrutia </solutionops-team>"
                }
              </p>
            </div>
            <div className="col-lg-4">
              <InlineShareButtons
                config={{
                  alignment: "center",
                  color: "social",
                  enabled: true,
                  font_size: 16,
                  networks: [
                    "whatsapp",
                    "linkedin",
                    "messenger",
                    "facebook",
                    "twitter",
                    "email",
                  ],
                  padding: 12,
                  radius: 0,
                  show_total: false,
                  size: 40,
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <Footer openWhatsapp={openWhatsapp} />
    </div>
  );
};

export default QueryInjectionBlogPage;
