import React from 'react';
import ReactDOM from 'react-dom';

import { Router, Switch, Route, Redirect } from 'react-router-dom';
// import * as serviceWorker from './serviceWorker';

import history from './history';

import Home from './pages/Home';
import Contact from './pages/Contact';
import DevelopmentPage from './pages/DevelopmentPage';
import AsyncAwaitBlogPage from './pages/Blog/AsyncAwaitBlogPage';
import StatePromisesBlogPage from './pages/Blog/StatePromisesBlogPage';
import DatesInJsBlogPage from './pages/Blog/DatesInJsBlogPage';
import JoinsMysqlBlogPage from './pages/Blog/JoinsMysqlBlogPage';
import HooksBlogPage from './pages/Blog/HooksBlogPage';
import PfxCertBlogPage from './pages/Blog/PfxCertBlogPage';
import CardinalidadesBlogPage from './pages/Blog/CardinalidadesBlogPage';
import ValidacionesVuestifyBlogPage from './pages/Blog/ValidacionesVuestifyBlogPage';
import AxiosFetchBlogPage from './pages/Blog/AxiosFetchBlogPage';
import QueryInjectionBlogPage from './pages/Blog/QueryInjectionBlogPage';
import CnameDnsBlogPage from './pages/Blog/CnameDnsBlogPage';
import CeremoniasScrumBlogPage from './pages/Blog/CeremoniasScrumBlogPage';
import GmailPhpBlogPage from './pages/Blog/GmailPhpBlogPage';
import FuntionesAlmacenadasBlogPage from './pages/Blog/FuntionesAlmacenadasBlogPage';
import RedesNeuronalesBlogPage from './pages/Blog/RedesNeuronalesBlogPage';
import TriggersSQLBlogPage from './pages/Blog/TriggersSQLBlogPage';
import CorsNodeBlogPage from './pages/Blog/CorsNodeBlogPage';
import ExploracionDatosBlogPage from './pages/Blog/ExploracionDatosBlogPage';
import DockerAppNodeBlogPage from './pages/Blog/DockerAppNodeBlogPage';
import MetodoHungaroBlogPage from './pages/Blog/MetodoHungaroBlogPage';
import FechasPhpBlogPage from './pages/Blog/FechasPhpBlogPage';
import AIBlogPage from './pages/Blog/AIBlogPage';

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Redirect from="/desarrollo-software-a-medida" to="/servicios/desarrollo-de-software"/>
    <Route exact path="/servicios/desarrollo-de-software" component={DevelopmentPage} />
    <Route exact path="/categorias/desarrollo-de-software/async-await-en-node-js" component={AsyncAwaitBlogPage} />
    <Route exact path="/categorias/desarrollo-de-software/tipos-de-estados-para-promesa-en-node-js" component={StatePromisesBlogPage} />
    <Route exact path="/categorias/desarrollo-de-software/como-usar-fechas-en-node-js" component={DatesInJsBlogPage} />
    <Route exact path="/categorias/desarrollo-de-software/tipos-de-joins-en-mysql" component={JoinsMysqlBlogPage} />
    <Route exact path="/categorias/desarrollo-de-software/hooks-mas-usados-react-js" component={HooksBlogPage} />
    <Route exact path="/categorias/desarrollo-de-software/exportar-certificado-y-claves-de-un-archivo-pfx-con-php" component={PfxCertBlogPage} />
    <Route exact path="/categorias/desarrollo-de-software/cardinalidades-bases-de-datos" component={CardinalidadesBlogPage} />
    <Route exact path="/categorias/desarrollo-de-software/validaciones-con-vuetify" component={ValidacionesVuestifyBlogPage} />
    <Route exact path="/categorias/desarrollo-de-software/inteligencia-artificial-y-su-tecnologia" component={AIBlogPage} />
    <Route exact path="/categorias/desarrollo-de-software/axios-vs-fetch" component={AxiosFetchBlogPage} />
    <Route exact path="/categorias/desarrollo-de-software/query-injection" component={QueryInjectionBlogPage} />
    <Route exact path="/categorias/desarrollo-de-software/cname-dns" component={CnameDnsBlogPage} />
    <Route exact path="/categorias/desarrollo-de-software/ceremonias-scrum" component={CeremoniasScrumBlogPage} />
    <Route exact path="/categorias/desarrollo-de-software/gmail-php" component={GmailPhpBlogPage} />
    <Route exact path="/categorias/desarrollo-de-software/funciones-almacenadas" component={FuntionesAlmacenadasBlogPage} />
    <Route exact path="/categorias/desarrollo-de-software/redes-neuronales" component={RedesNeuronalesBlogPage} />
    <Route exact path="/categorias/desarrollo-de-software/triggers-sql" component={TriggersSQLBlogPage} />
    <Route exact path="/categorias/desarrollo-de-software/cors-node" component={CorsNodeBlogPage} />
    <Route exact path="/categorias/desarrollo-de-software/exploracion-datos" component={ExploracionDatosBlogPage} />
    <Route exact path="/categorias/desarrollo-de-software/dockerizar-node" component={DockerAppNodeBlogPage} />
    <Route exact path="/categorias/desarrollo-de-software/metodo-hungaro" component={MetodoHungaroBlogPage} />
    <Route exact path="/categorias/desarrollo-de-software/fechas-php" component={FechasPhpBlogPage} />
    <Route exact path="/contacto-web" component={Contact} />
    <Redirect from="/*" to="/"/>
  </Switch>
);

ReactDOM.hydrate(
  <React.StrictMode>
    <Router history={history}>
     <div>
      <Routes/>
     </div>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

export {
  Routes
};

// serviceWorker.register();
