import { useRef } from 'react';

import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import Banner from '../components/Banner.js';

import Services from '../segments/Services.js';
import HostingForYourCompany from '../segments/HostingForYourCompany.js';
import OurClients from '../segments/OurClients.js';
import TechServices360 from '../segments/TechServices360.js';
import Plans from '../segments/Plans.js';
import Contact from '../segments/Contact.js';
import Development from '../segments/Development.js';

// import Loader from 'react-loader-spinner';

import TestingService from '../services/TestingService';

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const Home = () => {

  const serviceRef = useRef(null);
  const plansRef = useRef(null);
  const contactRef = useRef(null);
  // const [loading, setLoading] = useState(true);

  const openWhatsapp = () => {
    let url = "https://api.whatsapp.com/send/?phone=56991981647&text&app_absent=0";
    // window.gtag_report_conversion();
    window.location.assign(url)
  }

  const goTo = (page) => {
    window.location.assign(page)
    // let url = page;
    // window.href(url);
  }

  const openCall = () => {
    let url = "tel:0056997281587";
    window.open(url);
  }

  const testService = () => {
    TestingService.functionCustom().then((response) => {
      console.log(response);
    })
    .catch((err) => {
      console.log(err);
    });
  };

  // useEffect(()=>{
  //   setLoading(false);
  // }, [null]);
  // <div className="spinner-container">
  //  <Loader
  //    type="Oval"
  //    color="#00BFFF"
  //    height={50}
  //    width={50}
  //    className="spinner-element"
  //  />
  // </div>

  return (
    <div className="App">
      <Header
        goTo={goTo}
        scrollToRef={scrollToRef}
        openWhatsapp={openWhatsapp}
        openCall={openCall}
      />
      <Banner
        serviceRef={serviceRef}
        goTo={goTo}
        scrollToRef={scrollToRef}
        testService={testService}
      />
      <Services
        serviceRef={serviceRef}
        goTo={goTo}
        scrollToRef={scrollToRef}
      />
      {/* <HostingForYourCompany
        plansRef={plansRef}
        scrollToRef={scrollToRef}
      /> */}
      <Development
        goTo={goTo}
      />
      {/* <Plans
        plansRef={plansRef}
        openWhatsapp={openWhatsapp}
      /> */}
      <div className="segment-spacing-100-full"></div>
      <OurClients/>
      <div className="segment-spacing-100-full"></div>
      <TechServices360/>
      {/* <Contact
        contactRef={contactRef}
      /> */}
      <Footer
        openWhatsapp={openWhatsapp}
      />
    </div>
  );
}

export default Home;
