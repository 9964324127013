import { useRef, useEffect } from 'react';

import Header from '../../components/Header.js';
import Footer from '../../components/Footer.js';

import {InlineShareButtons} from 'sharethis-reactjs';

const JoinsMysqlBlogPage = () => {

  useEffect(() => {
    document.getElementById("canonical").href = "https://solutionops.cl/categorias/desarrollo-de-software/tipos-de-joins-en-mysql";
    document.getElementById("og_site_title").content = "Tipos de Joins en SQL, Mysql, Inner Join, Left Join, Right Join";
    document.getElementById("site_keywords").content = "Tipos de Joins en SQL, Mysql, Inner Join, Left Join, Right Join";
    document.getElementById("og_site_description").content = "Uso de Joins en MySQL, AWS Aurora, Left Join, Right Join, Inner Join";
    document.getElementById("site_description").content = "Uso de Joins en MySQL, AWS Aurora, Left Join, Right Join, Inner Join";
  }, []);

  const scrollToRef = (ref) =>  window.scrollTo({
    top: ref.current.offsetTop,
    behavior: 'smooth',
  });
  const contactRef = useRef(null);
    
  const openWhatsapp = () => {
    let url = "https://api.whatsapp.com/send/?phone=56991981647&text&app_absent=0";
    window.open(url);
  }

  const goTo = (page) => {
    window.location.assign(page)
    // let url = page;
    // window.href(url);
  }

  const openCall = () => {
    let url = "tel:0056991981647";
    window.open(url);
  }

  return (
    <div className="App">
        <Header
            goTo={goTo}
            scrollToRef={scrollToRef}
            openWhatsapp={openWhatsapp}
            openCall={openCall}
        />

        <div className="container-fluid bg-blog  bg-grey">
            <div className="container main-div-title bg-white">
                <div className="row">
                    <div className='col-md-12'>
                        <h1 className="blog-title">Tipos de Joins en SQL (MYSQL)</h1>
                        <h2 className="blog-subtitle">¿Cómo utilizar los diferentes tipos de JOINS y cuáles son sus casos de uso?</h2>
                        <br/>
                    </div>
                    <div className='col-lg-8'>

                            <p className='blog-text'>La sentencia JOIN nos permite combinar registros de diferentes tablas, mediante una condición de combinación que determinará cómo se relacionarán las tablas involucradas en la consulta. La posición que tiende a ocupar dentro de una consulta es después de la cláusula FROM, quedando algo como lo siguiente:</p>
                            <p className='blog-text' style={{ fontStyle:"italic", fontWeight: 300 }}>SELECT campo_1, campo_2, f… FROM tabla_a JOIN tabla_b ON tabla_a.campo = tabla_b.campo</p>

                            <p className='blog-text'>En este caso, los registros de la tabla A se relacionarán con los registros de la tabla B, mediante el campo especificado en la cláusula ON de la consulta. </p>
                            <p className='blog-text'>Existen diversos tipos de JOINS dependiendo del tipo de relación que se desea realizar. A continuación explicaremos tres de los más utilizados. </p>
                            
                            <h3 className='h3-title'>INNER JOIN</h3>
                            
                            <p className='blog-text'>Un INNER JOIN devolverá sólo aquellos registros que cumplan con la condición especificada. Es decir, aquellas filas que tengan coincidencias en ambas tablas a través del campo utilizado para realizar la intersección.</p>
                            <p className='blog-text'>A modo de ejemplo, situémonos en el caso en que tenemos dos tablas, una de personas y otra de vehículos, en donde una persona puede ser o no ser dueña de un vehículo. Además, la tabla vehículo contendrá una llave foránea para hacer referencia a la persona que es dueña de dicho vehículo. Si lo graficamos sería algo como esto:</p>

                            <img
                                loading="lazy"
                                width="auto"
                                height="auto"
                                className="img-blog"
                                alt="inner-join-solutionops"
                                src={"/static/img/blog/inner-join-solutionops.png"}/>

                            <p className='blog-text'>Si realizamos una INNER JOIN mediante el campo id de la tabla personas, podremos encontrar dos coincidencias en ambas tablas, dejando a la persona con id 3 fuera de los resultados.</p>

                            <p className='blog-text'>La consulta quedaría así:</p>
                            <p className='blog-text' style={{ fontStyle:"italic", fontWeight: 300 }}>SELECT p.id, p.nombre, p.apellido, v.marca, v.color  FROM personas as p INNER JOIN vehiculos as v ON p.id = v.id_persona</p>
                            <p className='blog-text'>Entregando el siguiente resultado:</p>
                            
                            <img
                                loading="lazy"
                                width="auto"
                                height="auto"
                                className="img-blog"
                                alt="inner-join-resultado"
                                src={"/static/img/blog/inner-join-resultado.png"}/>

                            <h3 className='h3-title'>LEFT JOIN</h3>

                            <p className='blog-text'>Un LEFT JOIN devolverá todos los registros presentes en la tabla declarada a la izquierda de la consulta y, de la tabla declarada a la derecha, solo devolverá aquellos registros en donde exista coincidencia mediante el campo utilizado para la unión.</p>
                            <p className='blog-text'>Para graficar la consulta, utilizemos un ejemplo similar al presentado en el caso anterior :</p>
                            <p className='blog-text' style={{ fontStyle:"italic", fontWeight: 300 }}>SELECT p.id, p.nombre, p.apellido, v.marca, v.color FROM personas as p LEFT JOIN vehiculos as v ON p.id = v.id_persona</p>

                            <p className='blog-text'>Al realizar la consulta, obtendríamos un resultado como este: </p>

                            <img
                                loading="lazy"
                                width="auto"
                                height="auto"
                                className="img-blog"
                                alt="left-join"
                                src={"/static/img/blog/left-join.png"}/>

                            <p className='blog-text'>Dado que la tabla personas se encuentra a la izquierda de la consulta, se mostrarán todos los registros de esta tabla, sin importar si existe o no coincidencia dentro de la tabla vehículo. En los casos en que no exista coincidencia, los campos se mostraran como null.</p>

                            <h3 className='h3-title'>RIGHT JOIN</h3>

                            <p className='blog-text'>El comportamiento de un RIGHT JOIN es muy similar al LEFT JOIN, pero invirtiendo la posición, es decir, devolverá todos los registros presentes en la tabla declarada a la derecha de la consulta y, de la tabla declarada a la izquierda, solo devolverá aquellos registros en donde exista coincidencia. </p>
                            <p className='blog-text'>A modo de ejemplo, podemos utilizar el mismo caso aplicado anteriormente e invertir la posición de las tablas, es decir, la consulta quedaría así: </p>
                            <p className='blog-text' style={{ fontStyle:"italic", fontWeight: 300 }}>SELECT p.id, p.nombre, p.apellido, v.marca, v.color FROM vehiculos as v RIGHT JOIN personas as p ON p.id = v.id_persona</p>
                            
                            <p className='blog-text'>Su resultado sería idéntico al presentado anteriormente:  </p>

                            <img
                                loading="lazy"
                                width="auto"
                                height="auto"
                                className="img-blog"
                                alt="left-join"
                                src={"/static/img/blog/right-join.png"}/>

                            <p className='blog-text'>Dado que esta vez la tabla personas se encuentra derecha de la consulta, y la tabla vehículos a la izquierda, al aplicar el RIGHT JOIN, obtendríamos todos los registros de personas, y solo aquellos registros que coincidan con el campo utilizado para la intersección de la tabla vehículos.</p>
                            <p className='blog-text'><strong>¡Pruebalo tu mismo!</strong></p>
                            <p className='blog-text'>Te dejamos el código necesario para que realices los ejemplos presentes en este artículo*.  </p>

                            <pre>
                                <code>
                                    {`

CREATE TABLE personas (
    id INT PRIMARY KEY,
    nombre VARCHAR(50),
    apellido VARCHAR(50),
    edad INT
  );
  
CREATE TABLE vehiculos (
    id INT PRIMARY KEY,
    marca VARCHAR(50),
    color VARCHAR(50),
    id_persona INT,
    FOREIGN KEY (id_persona) REFERENCES personas(id)
);
  
INSERT INTO personas (id, nombre, apellido, edad) VALUES
  (1, 'Julio', 'González', 38),
  (2, 'Roberto', 'Toro', 25),
  (3, 'Catalina', 'Perez', 19);
  
INSERT INTO vehiculos (id, marca, color, id_persona) VALUES
  (1, 'Chevrolet', 'Rojo', 1),
  (2, 'Kia', 'Blanco', 2);
  
                                    `}
                                </code>
                            </pre>


                            <p className='blog-text'>* Los ejempos han sido probados con MySQL 8.0.33. Es posible que con otro motor de base de datos sea necesario una leve adaptación.</p>

                            <p className='blog-text'>{'<solutionops-team> Autor: Jonathan Urrutia </solutionops-team>'}</p>

                    </div>
                    <div className='col-lg-4'>
                        <InlineShareButtons
                            config={{
                                alignment: 'center',
                                color: 'social',
                                enabled: true,
                                font_size: 16,
                                networks: [
                                'whatsapp',
                                'linkedin',
                                'messenger',
                                'facebook',
                                'twitter',
                                'email'
                                ],
                                padding: 12,
                                radius: 0,
                                show_total: false,
                                size: 40
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>

        <Footer
            openWhatsapp={openWhatsapp}
        />
    </div>
  );
}

export default JoinsMysqlBlogPage;
