import { useRef, useEffect } from "react";

import Header from "../../components/Header.js";
import Footer from "../../components/Footer.js";

import { InlineShareButtons } from "sharethis-reactjs";

const TriggersSQLBlogPage = () => {
  useEffect(() => {
    document.getElementById("canonical").href =
      "https://solutionops.cl/categorias/desarrollo-de-software/triggers-sql";
    document.getElementById("og_site_title").content = "Triggers en SQL";
    document.getElementById("site_keywords").content =
      "Automatizando acciones en tu base de datos: Explorando los Triggers en SQL";
    document.getElementById("og_site_description").content =
      "Automatizando acciones en tu base de datos: Explorando los Triggers en SQL";
    document.getElementById("site_description").content =
      "Automatizando acciones en tu base de datos: Explorando los Triggers en SQL";
  }, []);

  const scrollToRef = (ref) =>
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: "smooth",
    });
  const contactRef = useRef(null);

  const openWhatsapp = () => {
    let url =
      "https://api.whatsapp.com/send/?phone=56991981647&text&app_absent=0";
    window.open(url);
  };

  const goTo = (page) => {
    window.location.assign(page);
    // let url = page;
    // window.href(url);
  };

  const openCall = () => {
    let url = "tel:0056991981647";
    window.open(url);
  };

  return (
    <div className="App">
      <Header
        goTo={goTo}
        scrollToRef={scrollToRef}
        openWhatsapp={openWhatsapp}
        openCall={openCall}
      />

      <div className="container-fluid bg-blog  bg-grey">
        <div className="container main-div-title bg-white">
          <div className="row">
            <div className="col-md-12">
              <h1 className="blog-title">Triggers en SQL</h1>
              <h2 className="blog-subtitle">
                Automatizando acciones en tu base de datos: Explorando los
                Triggers en SQL
              </h2>
              <br />
              <br />
            </div>
            <div className="col-lg-8">
              <p className="blog-text">
                Los TRIGGERS en SQL son un componente de la base de datos que se
                activa automáticamente cuando ocurren ciertos eventos o cambios
                en la base de datos. Estos eventos pueden incluir operaciones de
                inserción, actualización o eliminación que se realizan en tablas
                específicas.
              </p>
              <h3 className="h3-title">Funcionamiento de los Triggers</h3>
              <p className="blog-text">
                Un trigger consta de un conjunto de instrucciones SQL y se
                asocia con una tabla en específico. Cuando ocurre un evento que
                dispara el trigger, se ejecutan las instrucciones definidas en
                el mismo.
              </p>
              <p className="blog-text">
                Por ejemplo, supongamos que tenemos una tabla con el campo fecha
                y queremos mantener un formato en específico. Podemos crear un
                trigger que se ejecute antes de que se inserte en la tabla.
              </p>
              <pre>
                <code>
                  {`
CREATE TRIGGER formatear_fecha

-- BEFORE para que se ejecute antes de insertar
BEFORE INSERT

-- Asigna el trigger a una tabla
ON nombre_tabla

-- Se ejecuta por cada fila que se inserte
FOR EACH ROW

-- Instrucciones que ejecutará el trigger
BEGIN
    SET NEW.fecha = DATE_FORMAT(NEW.fecha, '%Y-%m-%d');
END;

                                    `}
                </code>
              </pre>
              <p className="blog-text">
                La instrucción “SET NEW.fecha” asigna a la columna "fecha" el
                valor formateado utilizando la función DATE_FORMAT, que
                convierte la fecha en el formato deseado (en este caso,
                'YYYY-MM-DD').
              </p>
              <p className="blog-text">
                Al utilizar este trigger, cada vez que se inserte una fila en la
                tabla, la fecha se formateará automáticamente antes de ser
                almacenada en la base de datos, asegurando que siga el formato
                indicado.
              </p>

              <p className="blog-text">
                {
                  "<solutionops-team> Autor: Jojan Cardenas </solutionops-team>"
                }
              </p>
            </div>
            <div className="col-lg-4">
              <InlineShareButtons
                config={{
                  alignment: "center",
                  color: "social",
                  enabled: true,
                  font_size: 16,
                  networks: [
                    "whatsapp",
                    "linkedin",
                    "messenger",
                    "facebook",
                    "twitter",
                    "email",
                  ],
                  padding: 12,
                  radius: 0,
                  show_total: false,
                  size: 40,
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <Footer openWhatsapp={openWhatsapp} />
    </div>
  );
};

export default TriggersSQLBlogPage;
