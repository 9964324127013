import {useState} from 'react';
// import SweetAlert from 'sweetalert2-react';
import Reaptcha from 'reaptcha';
import SweetAlert2 from 'react-sweetalert2';


import TelegramService from '../services/TelegramService';

const Contact = ({
  contactRef
}) => {

  const [contact, setContact] = useState({
    name: '',
    company: '',
    rut: '',
    phone: '',
    email: '',
    message: ''
  });

  const [alert, setAlert] = useState({
    title: 'Error',
    message: 'Por favor intente nuevamente más tarde',
    icon: 'error',
    color: '#ff8000'
  });

  const [loader, setLoader] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [verified, setVerified] = useState(false)

  const submitForm = (e) => {
    e.preventDefault();

    console.log("Verified: " + verified);
    if(!verified){
      setAlert({
        title: 'Alerta',
        message: 'Debe validar el captcha',
        icon: 'error',
        color: '#ff8000'
      });

      window.gtag_report_conversion();

      setShowAlert(true);

      return;
    }

    setLoader(true);

    // {"name":"Elias Schotborgh","company":"ESGROUP","rut":"ESGROUP","phone":"04245256964","email":"eliasschotborgh@hotmail.com","message":"ddadada"}

    let msg = "";
    msg = `${msg}Nombre: ${contact.name}\n`;
    msg = `${msg}Empresa: ${contact.company}\n`;
    msg = `${msg}Rut: ${contact.rut}\n`;
    msg = `${msg}Teléfono: ${contact.phone}\n`;
    msg = `${msg}Email: ${contact.email}\n`;
    msg = `${msg}Mensaje: ${contact.message}\n`;

    let body = {
        "text": msg,
        "chat_id": `${process.env.REACT_APP_TELEGRAM_CHAT_ID}`
    };

    TelegramService.sendMessage(body).then((response) => {
      console.log(response);

      if(response.status===200){
        setAlert({
          title: 'Mensaje Enviado',
          message: 'Su mensaje ha sido enviado satisfactoriamente',
          icon: 'success',
          color: '#03a9f4'
        });

        setContact({
          name: '',
          company: '',
          rut: '',
          phone: '',
          email: '',
          message: ''
        });
      }

      setShowAlert(true);
      setLoader(false);
    })
    .catch((err) => {
      console.log(err);
      setShowAlert(true);
      setLoader(false);
    });
  }

  const handleChange = (e) => {
    setContact({
      ...contact,
      [e.target.name]: e.target.value
    });
  }

  const onVerify = recaptchaResponse => {
      setVerified(true);
  };

  const {name, company, rut, phone, email, message} = contact;

  return (
    <div className="container-fluid px-0 dark-background hght250" ref={contactRef}>
      <div className="row no-gutters pdng5perct">
        <div className="col-md-12 center-text-segment">
          <h2 className="white-text">CONTÁCTENOS</h2>
          <p className="p-text-white text-center">Indíquenos los detalles y necesidades de su proyecto y le contáctaremos...</p>
          <br/>
        </div>
        <div className="col-md-2"></div>
        <div className="col-md-8 white-text">
          <form onSubmit={submitForm}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="name">Nombre y Apellido:</label>
                  <input
                    type="text"
                    className="form-control input-custom-1"
                    placeholder="Nombre y Apellido"
                    autoComplete="off"
                    name="name"
                    onChange={handleChange}
                    value={name}
                    required/>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="phone">Teléfono:</label>
                  <input
                    type="number"
                    className="form-control input-custom-1"
                    name="phone"
                    onChange={handleChange}
                    value={phone}
                    placeholder="9 9999 9999"
                    autoComplete="off"
                    required/>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="email">Correo Electrónico:</label>
                  <input
                    type="email"
                    className="form-control input-custom-1"
                    name="email"
                    onChange={handleChange}
                    value={email}
                    placeholder="@"
                    autoComplete="off"
                    required/>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="message">Mensaje:</label>
                  <textarea
                    className="form-control input-custom-1"
                    name="message"
                    onChange={handleChange}
                    rows="3"
                    value={message}
                    ></textarea>
                </div>
              </div>

              <Reaptcha sitekey="6LepbDclAAAAAMkBVjbSIkrlT4Oc3QA06svce5ct" onVerify={onVerify}/>

              <div className="col-md-12">
                <button
                  type="submit"
                  className="btn btn-custom-submit-contact"
                  disabled={loader}>{!loader ? 'Enviar' : 'Cargando...'}</button>
              </div>
            </div>
            <SweetAlert2
              show={showAlert}
              title={alert.title}
              text={alert.message}
              icon={alert.icon}
              confirmButtonColor={alert.color}
              onConfirm={() => setShowAlert(false)}
            /> 
            {/* <SweetAlert
              show={showAlert}
              title={alert.title}
              text={alert.message}
              onConfirm={() => setShowAlert(false)}
            /> */}
          </form>
        </div>
      </div>
    </div>
  )
}

export default Contact;
